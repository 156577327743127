/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, authenticated, redirectToUpgrade, ...rest }) => {
    // Check wheather the props contain redirect(ie 'to' exist or not)
    const checkIfRedirectPresent = (props, restParam) => (restParam.to ? { ...props, to: restParam.to } : props);
    return (
        <Route
            {...rest}
            render={(props) =>
                authenticated ? (
                    redirectToUpgrade ? (
                        <Redirect to={{ pathname: '/upgrade', state: { from: props.location } }} />
                    ) : (
                        <Component {...checkIfRedirectPresent(props, rest)} />
                    )
                ) : (
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
            }
        />
    );
};

export default PrivateRoute;
