import { notification } from 'antd';
import { omit } from 'lodash';
import http from '../../common/http/httpProvider';
import { globalState } from '../../common/helper/globalState';
import { isEmpty } from '../../common/helper/commonMethods';
// eslint-disable-next-line import/no-cycle
import { propertyExist, getProperty } from '../../components/create-funnel/common/helper';
// eslint-disable-next-line import/no-cycle
import { validateTemplateAndTheme, pages } from './funnel';
// action name for save settings
export const UFE_SAVE_COMMON_SETTINGS = 'UFE_SAVE_COMMON_SETTINGS';

const TIMER_PATH = ['general', 'timer'];

export const deleteClearedSettings = (changedSettings, settings) => {
    // deleting validity when it is cleared
    const startDate = getProperty(changedSettings, ['general', 'validity', 'startDate']);
    const endDate = getProperty(changedSettings, ['general', 'validity', 'endDate']);

    const previousStartDate = getProperty(settings, ['general', 'validity', 'startDate']);
    const previousEndDate = getProperty(settings, ['general', 'validity', 'endDate']);

    if ((!startDate || !endDate) && (previousStartDate || previousEndDate)) {
        delete settings.general.validity;
    }
};

/**
 * `Returns` action of type `UFE_SAVE_COMMON_SETTINGS`
 * @param {*} settings data to be saved to redux
 */
export const saveCommonSettings = (settings) => ({
    type: UFE_SAVE_COMMON_SETTINGS,
    payload: settings,
});

// ////////////////////////////////////////////////////////////////////////////////
// /////////   GET METHODS
// ///////////////////////////////////////////////////////////////////////////////
export const formatSettings = (settings) => ({
    commonSettings: {
        ...settings,
    },
});
/**
 * validate depended settings
 * @param {*Object} settings
 */
export const validateSettings = (settings, newChangedSettings) => {
    if (propertyExist(settings, [...TIMER_PATH, 'status']) && !getProperty(settings, [...TIMER_PATH, 'status'])) {
        settings.general = omit(settings.general, 'timer.timer');
        settings.general = omit(settings.general, 'timer.expiryAction');
    }
    // clearing position when it is an empty string
    pages.forEach((page) => {
        if (getProperty(settings, ['position', page]) === '') {
            settings.position = omit(settings.position, page);
        }
        if (getProperty(settings, ['trigger', page]) === '') {
            settings.trigger = omit(settings.trigger, page);
        }
    });
    // clearing additional css
    if (getProperty(settings, ['design', 'additionalCSS']) === '') {
        settings.design = omit(settings.design, 'additionalCSS');
    }
    // deleting properties whose value is undefined
    deleteClearedSettings(newChangedSettings, settings);
};

export const getSettings = () =>
    http
        .getAction(`api/v1/settings`)
        .then((response) => {
            if (response) {
                return response.data;
            }
        })
        .catch((e) => {
            // handle error
        });

// ////////////////////////////////////////////////////////////////////////////////
// /////////   POST METHODS
// ///////////////////////////////////////////////////////////////////////////////

export const submitSettings = (settings) => {
    // for validating theme and template based on user plan
    if (!isEmpty(settings) && settings.design && settings.design.template && settings.design.theme) {
        const design = validateTemplateAndTheme(settings.design.template, settings.design.theme);
        settings.design.template = design.template;
        settings.design.theme = design.theme;
    }
    validateSettings(settings);
    // console.log(settings);
    http.postAction(`api/v1/settings`, { settings })
        .then((response) => {
            if (response.data.status === 'success') {
                notification.success({ message: 'Settings saved successfully' });
            }
        })
        .catch((e) => {
            const { translate: t } = globalState;
            notification.error({
                message: t('funnel.apiErrorMessage'),
            });
        });
};
