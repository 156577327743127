import { UFE_UPDATE_SOURCE_PRODUCT } from '../actions';

const initialState = {};
/**
 * Handle funnel data from redux state
 * @param {*} state  funnel state from redux store
 * @param {*} action dispatched action details contains `type` and `payload`
 */
export const funnel = (state = initialState, action) => {
    switch (action.type) {
        // save source prodduct to redux state
        case UFE_UPDATE_SOURCE_PRODUCT:
            return {
                ...state,
                sourceProductConfig: action.payload,
            };
        default:
            return { ...state };
    }
};
