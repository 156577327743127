import { SET_CURRENT_MONTH_COUNT, SET_LAST_THIRTY_DAYS_COUNT } from '../actions/orders';

const initialState = {
    currentMonthCount: 0,
    lastThirtyDaysCount: 0,
};

export const ordersCount = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_MONTH_COUNT:
            return {
                ...state,
                currentMonthCount: action.payload,
            };
        case SET_LAST_THIRTY_DAYS_COUNT:
            return {
                ...state,
                lastThirtyDaysCount: action.payload,
            };

        default:
            return {
                ...state,
            };
    }
};
