import React from 'react';
import { withRouter } from 'react-router-dom';
import Login from './Login';
import MainLayout from './MainLayout';
import { UfeLoader as Loader } from '../../common/loader';

function Authentication({ authenticated, authenticating, ...props }) {
    const IS_AUTHENTICATING = authenticating;
    const IS_AUTHENTICATED = authenticated && !authenticating;
    // const IS_AUTHENTICATED = true
    // eslint-disable-next-line no-nested-ternary
    return <>{IS_AUTHENTICATING ? <Loader /> : IS_AUTHENTICATED ? <MainLayout props={props} /> : <Login />}</>;
}

export default withRouter(Authentication);
