/* eslint-disable no-sequences */
/* eslint-disable no-undef */
/* eslint-disable prefer-spread */
/* eslint-disable no-multi-assign */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable camelcase */
/* eslint-disable no-empty */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-destructuring */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-expressions */
import { isEmpty, storageEngine, capitalize, verifyPlan, daysAgo } from '../common/helper/commonMethods';
import httpProvider from '../common/http/httpProvider';

export const initChatWidget = (user = {}) => {
    function initFreshChat() {
        let USER = user;
        // json parse error handle hack
        try {
            USER = !isEmpty(user) ? JSON.parse(user) : user;
        } catch (error) {}

        const { shop_owner = '' } = USER;

        const splitName = shop_owner.split(' ') || [];
        let firstName;
        let lastName;

        if ((splitName[0] || '').length > 3) {
            firstName = splitName[0];
            lastName = splitName.slice(1).join(' ');
        } else firstName = shop_owner;

        firstName = capitalize(firstName);

        const isNewUser =
            sessionStorage.getItem('isNewUser') === true || sessionStorage.getItem('isNewUser') === 'true';
        const { chatRestoreId } = USER;
        // console.log(`user`, user);
        // console.log(`USER`, USER);
        if (window.fcWidget) {
            window.fcWidget.init({
                token: process.env.REACT_APP_FRESHCHAT_TOKEN,
                host: 'https://wchat.freshchat.com',
                siteId: 'ufe.helixo.co',
                externalId: USER.myshopify_domain || null,
                restoreId: chatRestoreId || null,
                // Show FAQ Category with 'ufe' tag
                faqTags: {
                    // Array of Tags
                    tags: ['ufe'],
                    // For articles, the below value should be article.
                    // For article category, the below value should be category.
                    filterType: 'category', // Or filterType: 'article'
                },
                // "open": true,
                config: {
                    cssNames: {
                        expanded: 'custom_fc_expanded',
                        widget: 'custom_fc_frame',
                    },
                },
            });

            // Get user details
            window.fcWidget.user.get(function (userGetResponse) {
                const widget = document.querySelector('.fc-widget-small');

                const { status: userStatus, data: userData } = userGetResponse || {};

                console.log('user', userGetResponse);

                // find days ago
                // const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                // const TODAY = new Date();
                const storecreatedDate = new Date(USER.created_at);
                const firstInstalledDate = new Date(USER.first_installed);

                // const daysAgo = (sDate) => Math.round(Math.abs((TODAY - sDate) / oneDay));
                const daysOld = (date) => {
                    const dayAgo = daysAgo(date);
                    return dayAgo / 30 < 1 ? `${dayAgo} days old` : `${Math.round(Math.abs(dayAgo / 30))} months old`;
                };

                const storeAge = daysOld(storecreatedDate);
                const daysInApp = daysOld(firstInstalledDate);

                // Set user properties
                const userMetaProps = {
                    'Shopify Payments': USER.eligible_for_payments || null,
                    Currency: USER.currency || null,
                    'Customer Email': USER.customer_email || null,
                    multicurrencies:
                        (USER.enabled_presentment_currencies || []).length > 1
                            ? (USER.enabled_presentment_currencies || []).join(',')
                            : null || null,
                    'Shop Created': storeAge || null,
                    'Shopify Plan': `${USER.plan_display_name} - ${USER.plan_name}` || null,
                    'UFE Plan':
                        `${USER.ufe_plan}${
                            (USER.acceptedPrice && USER.acceptedPrice !== 0) || USER.ufe_plan !== 'free'
                                ? USER.acceptedPrice
                                : ''
                        }` || 'free',
                    'Priority Support': USER.prioritySupport ? '🚨 HIGH PRIORITY' : null,
                    'Shop Name': USER.name || null,
                    'Soft Review': USER.feedbackRating || null,
                    'Access Count': USER.access_count || null,
                    'First Installed': daysInApp || null,
                    'Last Feedback On': USER.lastFeedbackTime ? new Date(USER.lastFeedbackTime).toDateString() : null,
                    'Total Orders': USER.TOTAL_ORDERS || null,
                };

                // Only add if password enabled
                if (USER.password_enabled === 'true' || USER.password_enabled === true)
                    userMetaProps['Password Enabled'] = '🔐';
                else userMetaProps['Password Enabled'] = null;

                // add review
                if (USER.externalFeedback === 'true' || USER.externalFeedback === true)
                    userMetaProps.Reviewed = '⭐️⭐️⭐️⭐️⭐️';
                else userMetaProps.Reviewed = null;

                // Main properties
                const userProps = {
                    firstName, // user's first name
                    lastName, // user's last name
                    email: USER.email || null,
                    phone: USER.phone || null,
                };

                // If user doesn't exist, then set user props
                if (userStatus !== 200) {
                    window.fcWidget.user.setProperties({ ...userProps, ...userMetaProps });
                    window.fcWidget.on('user:created', function (resp) {
                        const status = resp && resp.status;
                        const data = resp && resp.data;
                        if (status === 200) {
                            if (data.restoreId) {
                                // Update restoreId in your database
                                console.log(`restoreid`, data.restoreId);
                                httpProvider
                                    .postAction('api/v1/user/restoreId', { restoreId: data.restoreId })
                                    .then((res) => {
                                        console.log(`saved restoreId`);
                                    });
                            }
                        }
                    });
                } else {
                    window.fcWidget.user.update({ ...userProps, meta: { ...userMetaProps } }).then((updateResp) => {
                        console.log(updateResp);
                    });
                }
            });
            // Auto open after 10 seconds
            setTimeout(() => {
                if (
                    ((USER &&
                        verifyPlan(USER.plan_name, true) &&
                        USER.access_count < 5 &&
                        !(USER.externalFeedback === 'true' || USER.externalFeedback === true)) ||
                        USER.testUser) &&
                    window.fcWidget
                ) {
                    window.fcWidget.track('ufe2beta_first_message', {
                        value: true,
                    });
                }
            }, 10000);
        }
    }
    function initialize(i, t) {
        let e;
        i.getElementById(t)
            ? initFreshChat()
            : (((e = i.createElement('script')).id = t),
              (e.async = !0),
              (e.src = 'https://wchat.freshchat.com/js/widget.js'),
              (e.onload = initFreshChat),
              i.head.appendChild(e));
    }
    function initiateCall() {
        initialize(document, 'freshchat-js-sdk');
    }

    initiateCall();
};

export const initGoogleTagManager = () => {
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l != 'dataLayer' ? `&l=${l}` : '';
        j.async = true;
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', process.env.REACT_APP_GTAG_ID);
};

export const initRedditPixel = () => {
    // <!-- Reddit Pixel -->
    !(function (w, d) {
        if (!w.rdt) {
            var p = (w.rdt = function () {
                p.sendEvent ? p.sendEvent.apply(p, arguments) : p.callQueue.push(arguments);
            });
            p.callQueue = [];
            const t = d.createElement('script');
            (t.src = 'https://www.redditstatic.com/ads/pixel.js'), (t.async = !0);
            const s = d.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(t, s);
        }
    })(window, document);
    if (window.rdt) {
        rdt('init', REACT_APP_REDDIT_PIXEL_ID);
        rdt('track', 'PageVisit');
    }

    // <!-- DO NOT MODIFY -->
    // <!-- End Reddit Pixel -->
};

export const initHotjarAnalytics = () => {
    (function (h, o, t, j, a, r) {
        h.hj =
            h.hj ||
            function () {
                (h.hj.q = h.hj.q || []).push(arguments);
            };
        h._hjSettings = { hjid: 1420238, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
};

export const updateChatWidget = (user = {}, meta) => {
    let USER = user;
    // json parse error handle hack
    try {
        USER = !isEmpty(user) ? JSON.parse(user) : user;
    } catch (error) {}
    const userProps = {
        email: USER.email || null,
    };
    const userMetaProps = {
        'Total Orders': meta.TOTAL_ORDERS || null,
    };
    if (window.fcWidget)
        window.fcWidget.on('widget:loaded', function (resp) {
            console.log('Widget Loaded');
            window.fcWidget.user.update({ ...userProps, meta: { ...userMetaProps } }).then((updateResp) => {
                console.log(updateResp);
            });
        });
};
// export initHotjarAnalytics;
// exports.initGoogleTagManager = initGoogleTagManager;
// exports.initChatWidget = initChatWidget;
