import axios from 'axios';
import API_URL from '../../environments/Environments';
// import { storeAuthenticate } from './../authentication/storeAuthentication';

const getBaseUrl = (url) => API_URL.url + url;
// return url && url[0] === "/" ? url : "/" + url;
const httpProvider = {
    getAction: (url, config = {}) => axios.get(getBaseUrl(url), config).catch((err) => {}),
    postAction: (url, payload, config = {}) => axios.post(getBaseUrl(url), payload, config).catch((err) => {}),
    deleteAction: (url, payload, config = {}) => axios.delete(getBaseUrl(url), config).catch((err) => {}),
    patchAction: (url, payload, config = {}) => axios.patch(url, payload, config).catch((err) => {}),
    setAuthorizationToken: (token) => {
        if (token) {
            axios.defaults.headers.common['x-ufe-auth'] = token;
            axios.defaults.withCredentials = true;
        } else {
            delete axios.defaults.headers.common['x-ufe-auth'];
        }
    },
};

export default httpProvider;
