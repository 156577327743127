import { UPDATE_EVENTS } from '../actions/events';

export const events = (state = [], action) => {
    switch (action.type) {
        case UPDATE_EVENTS:
            return [...action.payload];
        default:
            return [...state];
    }
};
