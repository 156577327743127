import { notification } from 'antd';
import { merge } from 'lodash';
// eslint-disable-next-line import/no-cycle
import { store } from '..';
import http from '../../common/http/httpProvider';
import {
    getIt,
    deepClone,
    isEmpty,
    groupBy,
    sort,
    findObjectByKey,
    objectDifference,
    storageEngine,
} from '../../common/helper/commonMethods';
import { globalState } from '../../common/helper/globalState';
import {
    filters,
    templates,
    pageSpecificSettings,
    newFunnelData,
    settings,
} from '../../components/create-funnel/common/dataProvider';
// eslint-disable-next-line import/no-cycle
import { propertyExist, getProperty, setProperty } from '../../components/create-funnel/common/helper';
// eslint-disable-next-line import/no-cycle
import { validateSettings } from './settings';

// source product
export const UFE_UPDATE_SOURCE_PRODUCT = 'UFE_UPDATE_SOURCE_PRODUCT';

export const ASCII_VALUE_OF_A = 65;

const SPLIT_TEST_COUNTS = {};

export const pages = ['allPages', 'productPage', 'cartPage', 'thankyouPage', 'homePage', 'collectionPage'];

/**
 * `Returns` action of type `UFE_UPDATE_SOURCE_PRODUCT`
 * @param {*} config  source product config,
 * `products` - product details,
 * `type` - product it belongs to collection or tag
 * `value` - selected collection value or tag valua
 */
export const updateSourceProductConfig = (config) => ({ type: UFE_UPDATE_SOURCE_PRODUCT, payload: config });

export const getSplitTestGroup = (splitTestIndex) => String.fromCharCode(ASCII_VALUE_OF_A + splitTestIndex);
// get user plan from local storage
const getUserPlan = () => {
    const user = storageEngine.get('user', true);
    if (user && user.ufe_plan) {
        return user.ufe_plan;
    }
    return 'free';
};
/**
 * to know is any split test changed
 * @param {*} formValues
 * @param {*} spliTestCount actual split test count in submmitted funnel
 */
const checkSplitTestCount = (formValues) => {
    const splitTestChanged = [];
    (pages || []).forEach((page) => {
        if (formValues[page]) {
            if ((formValues[page].splitTest || []).length !== SPLIT_TEST_COUNTS[page]) {
                splitTestChanged.push(page);
            }
        }
    });
    formValues.splitTestChanged = splitTestChanged;
};
// to get free default template
export const getDefaultTemplate = () => {
    const userPlan = getUserPlan();
    const template = (templates || []).find((eachTemplate) => eachTemplate.plan.indexOf(userPlan) !== -1);
    const theme = (template.themes || []).find((eachTheme) => eachTheme.plan.indexOf(userPlan) !== -1);
    return {
        template: template.template,
        theme: theme.theme,
    };
};
// validate template and theme based on user plan
export const validateTemplateAndTheme = (selectdTemplate, selectdTheme) => {
    const userPlan = getUserPlan();
    const template = (templates || []).find((eachTemplate) => eachTemplate.template === selectdTemplate);
    if (template && template.plan.indexOf(userPlan) === -1) {
        return getDefaultTemplate();
    }
    const theme = (template.themes || []).find((eachTheme) => eachTheme.theme === selectdTheme);
    if (theme && theme.plan.indexOf(userPlan) === -1) {
        return getDefaultTemplate();
    }
    return {
        template: selectdTemplate,
        theme: selectdTheme,
    };
};
/**
 * format filter
 * @param {*} form values
 */
const formatFilterBeforeSubmmit = (formValues) => {
    const filterArray = [];
    // eslint-disable-next-line no-shadow
    const { filters } = formValues;
    (filters || []).forEach((individualFilter) => {
        if (individualFilter.active) {
            const value = {};
            value[individualFilter.operator] = individualFilter.value;
            filterArray.push({
                name: individualFilter.name,
                subCat: individualFilter.subCat,
                rules: {
                    and: [value],
                },
            });
        }
    });
    formValues.filters = [...filterArray];
};
// check if 30 + variants propducts exist or not
const checkLargeProducts = (formValues) => {
    const fetchNeededProducts = [];
    (formValues.upsells || []).forEach((upsell) => {
        (upsell.offers || []).forEach((offer) => {
            (offer.products || []).forEach((product) => {
                if ((product.variants || []).length >= 30) {
                    fetchNeededProducts.push(product.id);
                }
            });
        });
    });
    formValues.fetchNeededProducts = fetchNeededProducts;
};
/**
 * copy upsellSettings diffrence to upsellSettings
 * delete upsellSetting difference
 * @param {*} each upsell
 */
const formatUpsellSettingsBeforeSubmmit = (upsell, formValues) => {
    upsell.upsellSettings = upsell.upsellSettingsDifference || {};
    if (upsell.upsellSettingsDifference) {
        delete upsell.upsellSettingsDifference;
    }

    // delete bundle property in widgetSpecific when upsell type is not bundle
    if (
        upsell.upsellType !== 'bundle' &&
        upsell.upsellSettings.widgetSpecific &&
        upsell.upsellSettings.widgetSpecific.bundle
    ) {
        delete upsell.upsellSettings.widgetSpecific.bundle;
    }
    if (
        upsell.upsellType !== 'volume_discount' &&
        upsell.upsellSettings.widgetSpecific &&
        upsell.upsellSettings.widgetSpecific.volumeDiscount
    ) {
        delete upsell.upsellSettings.widgetSpecific.volumeDiscount;
    }
    if (
        upsell.upsellType === 'volume_discount' &&
        upsell.upsellSettings.widgetSpecific &&
        upsell.upsellSettings.widgetSpecific.volumeDiscount &&
        upsell.upsellSettings.widgetSpecific.volumeDiscount.addDefaultTriggerProductAsVolumeDiscount
    ) {
        delete upsell.upsellSettings.widgetSpecific.volumeDiscount.addDefaultTriggerProductAsVolumeDiscount;
    }
    // if (upsell.page === 'thankyouPage') {
    //     upsell.upsellSettings = merge({}, upsell.upsellSettings, {
    //         general: {
    //             skipCart: true,
    //         },
    //     });
    // }
    upsell.upsellSettings = merge(upsell.upsellSettings, pageSpecificSettings[upsell.page]);
    // verify template and theme
    if (upsell.upsellSettings.design && upsell.upsellSettings.design.template && upsell.upsellSettings.design.theme) {
        const design = validateTemplateAndTheme(
            upsell.upsellSettings.design.template,
            upsell.upsellSettings.design.theme
        );
        upsell.upsellSettings.design.template = design.template;
        upsell.upsellSettings.design.theme = design.theme;
    } else if (
        ((formValues.funnelSettings || {}).design || {}).theme &&
        ((formValues.funnelSettings || {}).design || {}).template
    ) {
        const design = validateTemplateAndTheme(
            formValues.funnelSettings.design.template,
            formValues.funnelSettings.design.theme
        );
        formValues.funnelSettings.design.template = design.template;
        formValues.funnelSettings.design.theme = design.theme;
    } else if (
        upsell.upsellSettings.design &&
        upsell.upsellSettings.design.theme &&
        ((formValues.funnelSettings || {}).design || {}).template
    ) {
        const design = validateTemplateAndTheme(
            formValues.funnelSettings.design.template,
            upsell.upsellSettings.design.theme
        );
        upsell.upsellSettings.design.theme = design.theme;
    } else if (upsell.upsellSettings.design && upsell.upsellSettings.design.template) {
        delete upsell.upsellSettings.design.template;
    }
    // deleting properties integrateCustomerAddress and rejectActionOnClose when style is not popup
    // it is style specific properties
    if (
        upsell.upsellSettings.general &&
        upsell.upsellSettings.general.upsellStyle &&
        upsell.upsellSettings.general.upsellStyle !== 'popup'
    ) {
        if (propertyExist(upsell.upsellSettings, ['general', 'integrateCustomerAddress']))
            delete upsell.upsellSettings.general.integrateCustomerAddress;
        if (propertyExist(upsell.upsellSettings, ['general', 'rejectActionOnClose']))
            delete upsell.upsellSettings.general.rejectActionOnClose;
    }
    // immediate trigger property is in flaoting and popup
    if (
        upsell.upsellSettings.general &&
        upsell.upsellSettings.general.upsellStyle &&
        upsell.upsellSettings.general.upsellStyle !== 'popup' &&
        upsell.upsellSettings.general.upsellStyle !== 'floating'
    ) {
        if (propertyExist(upsell.upsellSettings, ['general', 'immediateTrigger']))
            delete upsell.upsellSettings.general.immediateTrigger;
        if (propertyExist(upsell.upsellSettings, ['general', 'popupDelay']))
            delete upsell.upsellSettings.general.popupDelay;
    }
    // validateSettings(upsell.upsellSettings);
    // else if (
    //     propertyExist(upsell.upsellSettings, ['general', 'popupDelay']) &&
    //     propertyExist(upsell.upsellSettings, ['general', 'immediateTrigger']) &&
    //     upsell.upsellSettings.general.immediateTrigger
    // )
    //     delete upsell.upsellSettings.general.popupDelay;
};

// /////////FORMAT FUNNEL SETTINGS /////////////
export const formatFunnelSettingsBeforeSubmmit = (formValues) => {
    if (formValues.funnelSettings) {
        const commonSettings = store.getState().settings || {};
        if (isEmpty(formValues.funnelSettings)) {
            return {};
        }
        // merging global settings with funnel settings
        const oldSettings = merge({}, commonSettings, formValues.funnelSettingsDifference);
        // getting differnce from old settings and new settings
        const objDifference = objectDifference(formValues.funnelSettings, oldSettings);
        // merging old changed settings to new changed settings
        const newSettings = merge({}, formValues.funnelSettingsDifference, objDifference);
        // deleting unwanted settings from funnel settings
        validateSettings(newSettings, objDifference);
        return { ...newSettings };
    }
    return {};
};
/**
 * Format every array of upsells and splitTest into one single array of upsells
 * @param {*} formValues funnel details to be submitted
 */

export const formatFunnelBeforeSubmit = (formValues) => {
    // format filter
    formatFilterBeforeSubmmit(formValues);
    // check splitTests count in each page inorder to set splitTestChanged property
    checkSplitTestCount(formValues);
    formValues.upsells = [];
    pages.forEach((page) => {
        // split test from corresponding page
        const { splitTest } = formValues[page] || {};

        // Loop over every split test
        if ((splitTest || []).length === 1) {
            (splitTest[0].upsells || []).forEach((upsell, upsellIndex) => {
                upsell.page = page;
                // assign a sort order to every upsell to keep its position
                upsell.sortOrder = upsellIndex + 1;
                // assign a split test grop
                delete upsell.splitTestGroup;
                /**
                 * behaviour will be split test for every upsell that has split test exists
                 */
                upsell.behaviour = 'upsell';

                // format settings
                formatUpsellSettingsBeforeSubmmit(upsell, formValues);

                const downsells = [];
                (upsell.downsell || []).forEach((downsell) => {
                    formatUpsellSettingsBeforeSubmmit(downsell, formValues);
                    downsells.push(downsell);
                });
                upsell.downsell = downsells;

                formValues.upsells.push(upsell);
            });
        } else {
            (splitTest || []).forEach(({ upsells }, splitTestIndex) => {
                (upsells || []).forEach((upsell, upsellIndex) => {
                    upsell.page = page;
                    // assign a sort order to every upsell to keep its position
                    upsell.sortOrder = upsellIndex + 1;
                    // assign a split test grop
                    upsell.splitTestGroup = (splitTest || []).length ? getSplitTestGroup(splitTestIndex) : null;
                    /**
                     * behaviour will be split test for every upsell that has split test exists
                     */
                    upsell.behaviour = 'splitTest';
                    // format settings
                    formatUpsellSettingsBeforeSubmmit(upsell, formValues);
                    const downsells = [];
                    (upsell.downsell || []).forEach((downsell) => {
                        formatUpsellSettingsBeforeSubmmit(downsell, formValues);
                        downsells.push(downsell);
                    });
                    upsell.downsell = downsells;

                    formValues.upsells.push(upsell);
                });
            });
        }
        delete formValues[page];
    });
    // format funnel settings
    // NOTE: keep only changes
    formValues.funnelSettings = formatFunnelSettingsBeforeSubmmit(formValues);
};

/**
 * `Returns` upsell count by pages
 * @param {*} upsellFunnels  upsells from different pages
 */
export const findUpsellCountInCategory = (upsellFunnels) => {
    const count = {
        productPage: 0,
        cartPage: 0,
        checkoutPage: 0,
        thankyouPage: 0,
    };
    Object.keys(upsellFunnels || {}).forEach((a) => {
        let i = 0;
        i =
            getIt(upsellFunnels, `${a}.upsellProducts.upsells`, []).length +
            getIt(upsellFunnels, `${a}.splitTest`, []).length +
            getIt(upsellFunnels, `${a}.downSell`, []).length;
        count[a] = i;
    });
    return count;
};
/**
 * Format filters to form format
 * @param {*} funnel funnel details
 */
const formatFilters = (funnel = {}) => {
    const filterArray = [];
    (filters || []).forEach((filter) => {
        const match = findObjectByKey(funnel.filters, 'name', filter.name);
        if (match) {
            const rules = match.rules.and[0];
            const operator = Object.keys(rules)[0];
            const value = rules[operator];
            filterArray.push({
                active: true,
                name: match.name,
                subCat: match.subCat,
                operator,
                value,
            });
        } else {
            filterArray.push(filter);
        }
    });
    funnel.filters = [...filterArray];
};
/**
 * format upsell settings for using in form
 * upsellSettings holds difference only move it to upsellSettingsDifference
 * take settings from funnel settings and merge with the difference and store to upsellSettings
 * @param {*} single upsell
 * @param {*} funnel data
 */
const formatUpsellSettings = (upsell, funnel = {}) => {
    upsell.upsellSettingsDifference = upsell.upsellSettings;
    upsell.upsellSettings = merge({}, funnel.funnelSettings, upsell.upsellSettings);
    // for existing users
    // for allowVariantSelection and quantity selctor
    // allowVariantSelection is true make quantity selector true
    if (
        upsell.upsellType === 'volume_discount' &&
        (getProperty(upsell.upsellSettings, ['widgetSpecific', 'volumeDiscount', 'allowVariantSelection']) ||
            !propertyExist(upsell.upsellSettings, ['widgetSpecific', 'volumeDiscount', 'allowVariantSelection']))
    ) {
        setProperty(upsell.upsellSettings, 'general.allowQuantitySelector', true);
        setProperty(upsell.upsellSettingsDifference, 'general.allowQuantitySelector', true);
    }
};

/**
 * Format upsells array into upsells and split test by using page value
 * @param {*} funnel funnel details
 */
export const formatUpsells = (funnel = {}) => {
    // funnel.upsells.forEach((upsell) => {
    //     if (upsell.page === 'product') upsell.page = 'productPage';
    //     else if (upsell.page === 'cart') upsell.page = 'cartPage';
    //     else if (upsell.page === 'thankyou') upsell.page = 'thankyouPage';
    // });
    // format each upsell settings
    const upsells = [];
    (funnel.upsells || []).forEach((upsell) => {
        formatUpsellSettings(upsell, funnel);
        const downsells = [];
        (upsell.downsell || []).forEach((downsell) => {
            formatUpsellSettings(downsell, funnel);
            downsells.push(downsell);
        });
        upsell.downsell = downsells;
        upsells.push(upsell);
    });
    funnel.upsells = upsells;
    // grouping upsells by page type
    const groupedPages = groupBy(funnel.upsells, 'page');
    pages.forEach((page) => {
        funnel[page] = {};
        funnel[page].splitTest = [];
        if (groupedPages[page]) {
            // group by split test A, B, C ...
            const splitTestGroups = groupBy(groupedPages[page], 'splitTestGroup');
            // sort split test group for keeping their own position
            const sortedSplitTestKeys = Object.keys(splitTestGroups).sort();
            // Take keys one by one like A, B, C ...
            sortedSplitTestKeys.forEach((splitTestGroupKey) => {
                // sort upsells, it can have multiple upsells in one split test, sort them to keep its position
                // eslint-disable-next-line no-shadow
                const upsells = sort(splitTestGroups[splitTestGroupKey], 'sortOrder');
                funnel[page].splitTest.push({
                    upsells,
                });
            });
        } else {
            funnel[page] = {
                splitTest: [],
            };
        }
    });
    delete funnel.upsells;
};

/**
 * Find trackIds of existing products
 * Inorder to identify which products are deleted from upsell
 * @param {*} funnel funnel details
 */
export const findTrackIdsOfExistingProducts = (funnel) => {
    const trackIds = [];
    // Loop over every pages (productPage | cartPage | thankyouPage)
    pages.forEach((page) => {
        const { splitTest: splitTests } = funnel[page] || {};
        // Loop over every split test
        (splitTests || []).forEach((splitTest, splitTestIndex) => {
            const { upsells } = splitTest || {};
            (upsells || []).forEach((upsell, upsellIndex) => {
                // offer contains the product details
                const { offers = [], downsell } = upsell;
                // loop over every upsell offers
                (offers || []).forEach((offer, offerIndex) => {
                    const { products } = offer;
                    (products || []).forEach((product, productIndex) => {
                        trackIds.push({
                            trackId: product.trackId,
                            productId: product.id,
                            path: `${page}.splitTest.${splitTestIndex}.upsells.${upsellIndex}.offers.${offerIndex}.products.${productIndex}`,
                            title: product.title,
                        });
                    });
                    // Loop over every downsell offers
                });
                ((downsell || {}).offers || []).forEach((offer, offerIndex) => {
                    const { products } = offer;
                    (products || []).forEach((product, productIndex) => {
                        trackIds.push({
                            trackId: product.trackId,
                            productId: product.id,
                            path: `${page}.splitTest.${splitTestIndex}.upsells.${upsellIndex}.downsell.offers.${offerIndex}.products.${productIndex}`,
                            title: product.title,
                        });
                    });
                });
            });
        });
    });
    funnel.existingProductTrackIds = trackIds;
};

// count split tests for splitTestChanged property
const countSplitTests = (funnel) => {
    (pages || []).forEach((page) => {
        if (funnel[page]) {
            SPLIT_TEST_COUNTS[page] = (funnel[page].splitTest || []).length;
        }
    });
    // console.log(SPLIT_TEST_COUNTS);
};
// to count upsells page wise
// save to form values
const countUpsell = (funnel) => {
    const upsellCount = {
        allPages: 0,
        productPage: 0,
        cartPage: 0,
        thankyouPage: 0,
        homePage: 0,
        collectionPage: 0,
    };
    (pages || []).forEach((page) => {
        let count = 0;
        ((funnel[page] || {}).splitTest || []).forEach((spliTestData) => {
            count += (spliTestData.upsells || []).length;
        });
        upsellCount[page] = count;
    });
    // console.log(upsellCount);
    funnel.upsellCount = upsellCount;
};

/**
 * Find the deleted products in the upsell during updation
 * @param {*} funnel funneldetails
 */
export const findDeletedProductsByTrackId = (funnel) => {
    //
    const deletedProducts = [];
    const trckIds = deepClone(funnel.existingProductTrackIds || []);
    /**
     * check the product is existing in funnel by checking pathh
     */
    (trckIds || []).forEach((trackId) => {
        const product = getIt(funnel, trackId.path, {});
        if (isEmpty(product) || (product && +product.id !== +trackId.productId)) {
            deletedProducts.push(trackId.trackId);
        }
    });
    if (deletedProducts.length) {
        funnel.deletedTrackIds = deletedProducts.filter(Boolean);
    }
};
// format funnel settings before setting form values
export const formatFunnelSettings = (funnel) => {
    const commonSettings = store.getState().settings || {}; // common settings
    funnel.funnelSettingsDifference = { ...funnel.funnelSettings };
    funnel.funnelSettings = merge({}, commonSettings, funnel.funnelSettings);
};
// ////////////////////////////////////////////////////////////////////////////////
// /////////   GET METHODS
// ///////////////////////////////////////////////////////////////////////////////

/**
 * Fetch funnel details
 * @param {*} id funnel id
 */
export const getFunnel = (id) =>
    http
        .getAction(`api/v1/funnel/${id}`)
        .then((response) => {
            const funnel = response.data;
            funnel.id = id;
            formatFunnelSettings(funnel);
            formatUpsells(funnel);
            formatFilters(funnel);
            findTrackIdsOfExistingProducts(funnel);
            countUpsell(funnel);
            countSplitTests(funnel);
            console.log(funnel);
            return funnel;
            //  dispatch(upsellFunnelLoadingSuccess());
        })
        .catch((e) => {
            // handle error
        });

// ////////////////////////////////////////////////////////////////////////////////
// /////////   POST METHODS
// ///////////////////////////////////////////////////////////////////////////////

/**
 * Save or Update funnel details
 * @param {*} formValues funnel details
 * @param {*} isLivePreview boolean value, whether to show preview in live store
 * @param {*} fn callback function
 */
export const submitFunnel = (formValues, isLivePreview = false, fn) => {
    // if the user wants to the preview in live store, add flag
    if (isLivePreview) {
        formValues.isPreview = true;
    }
    // name the funnel, if it doesnt have value
    formValues.funnelName = formValues.funnelName
        ? formValues.funnelName
        : `Funnel #${Math.floor(Math.random() * 100000 + 1)}`;
    findDeletedProductsByTrackId(formValues);
    formatFunnelBeforeSubmit(formValues);
    // validate funnel template and theme
    if (formValues.funnelSettings && formValues.funnelSettings.design && formValues.funnelSettings.design.template) {
        const design = validateTemplateAndTheme(
            formValues.funnelSettings.design.template,
            formValues.funnelSettings.design.theme
        );
        formValues.funnelSettings.design.template = design.template;
        formValues.funnelSettings.design.theme = design.theme;
    }
    //
    if (formValues.triggerSource === 'allProducts') {
        formValues.trigger = {};
        formValues.triggerProductVariants = [];
    }
    if (
        formValues.funnelSettings &&
        formValues.funnelSettings.widgetSpecific &&
        formValues.funnelSettings.widgetSpecific.volumeDiscount &&
        formValues.funnelSettings.widgetSpecific.volumeDiscount.addDefaultTriggerProductAsVolumeDiscount
    ) {
        delete formValues.funnelSettings.widgetSpecific.volumeDiscount.addDefaultTriggerProductAsVolumeDiscount;
    }
    checkLargeProducts(formValues);
    console.log('submitted form values', formValues);
    const postUrl = `api/v1/funnel`;
    // return null;
    http.postAction(postUrl, formValues)
        .then((response) => {
            const { translate: t } = globalState;
            if (response.data.status === 'success' && !isLivePreview) {
                const message = formValues.id ? t('funnel.successUpdateMessage') : t('funnel.successSaveMessage');
                notification.success({ message });
                if (fn) {
                    fn({
                        status: 'success',
                        data: response.data,
                    });
                    const isNewUser = storageEngine.get('isNewUser', false, 'session');
                    const showFirstFunnelModal = storageEngine.get('showFirstFunnelModal');
                    if (
                        isNewUser &&
                        isNewUser === `"true"` &&
                        (!showFirstFunnelModal === undefined || showFirstFunnelModal === null)
                    ) {
                        storageEngine.set('showFirstFunnelModal', true);
                        if (!(formValues.upsells || []).find((upsell) => upsell.page !== 'cartPage')) {
                            storageEngine.set('showFunnelInCart', true);
                        }
                    }

                    const { upsells = [] } = formValues;
                    const imageUrls = [];
                    upsells.forEach((upsell) => {
                        /**
                         * loop thr' offers
                         */
                        const { offers = [] } = upsell;
                        offers.forEach((offer) => {
                            /**
                             * loop thr' products
                             */
                            const { products = [] } = offer;
                            products.forEach((product) => {
                                if (getIt(product, 'customProperties.image')) {
                                    imageUrls.push(getIt(product, 'customProperties.image', ''));
                                }
                            });
                        });
                    });
                    /* * posting to backend */
                    http.postAction('api/v1/funnel/product/update/image-tracker', { imageUrls })
                        .then((res) => {
                            // res
                        })
                        .catch((err) => {
                            // err
                        });
                }
            } else if (isLivePreview) {
                // open new tab navigate to new page
            }
        })
        .catch((e) => {
            const { translate: t } = globalState;
            notification.error({
                message: t('funnel.apiErrorMessage'),
            });
            if (fn) {
                fn({
                    status: 'error',
                    data: null,
                });
            }
        });
};
// ////////////////////////////////////////////////////////////////////////////////
// /////////   CREATE NEW FUNNEL
// ///////////////////////////////////////////////////////////////////////////////

export const createNewFunnel = async () => {
    const funnelData = newFunnelData();
    const commonSettings = store.getState().settings || {};
    if (!isEmpty(commonSettings)) {
        funnelData.funnelSettings = {
            ...deepClone(commonSettings),
        };
    } else {
        funnelData.funnelSettings = {
            ...deepClone(settings),
        };
    }

    return funnelData;
};
// get funnel view
export const getFunnelView = (id) =>
    http
        .getAction(`api/v1/funnel/${id}/view`)
        .then((response) => {
            const funnel = response.data;
            funnel.id = id;
            formatFunnelSettings(funnel);
            formatUpsells(funnel);
            formatFilters(funnel);
            // findTrackIdsOfExistingProducts(funnel);
            countUpsell(funnel);
            countSplitTests(funnel);
            return funnel;
        })
        .catch((e) => {
            // handle error
        });

// ////////////////////////////////////////////////////////////////////////////////
// /////////   MIGRATE FUNNELS
// ///////////////////////////////////////////////////////////////////////////////

export const postMigration = (fn) => {
    const postUrl = 'api/v1/migration/funnels';
    http.postAction(postUrl)
        .then((response) => {
            if ((response.data || {}).status === 'success' && fn) {
                fn();
            }
        })
        .catch((e) => {
            notification.error({
                message: 'Error while importing funnels',
            });
        });
};
