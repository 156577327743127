import http from '~common/http/httpProvider';

export const UPDATE_NOTOFICATIONS = 'UPDATE_NOTIFICATIONS';

export const updateNotifications = (notifications) => ({
    type: UPDATE_NOTOFICATIONS,
    payload: [...notifications],
});

export const getEventsAndNotifications = () =>
    http.getAction('api/v1/promotion/events').then((res) => {
        if (res && res.status === 200) {
            return res.data;
        }
    });
