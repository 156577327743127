import { BellOutlined, UserOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Badge, Popover, Avatar, Button, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import Notification from './notification';
import { storageEngine } from '../../../../common/helper/commonMethods';
import { STARTUP_PLAN } from '../../../../common/constants';
import UserMenuList from './user-content';
import './MenuUserOptions.scss';

export default withRouter(function ({ history }) {
    const [showNotification, toggleNotification] = useState(false);

    const [authenticatedUser] = useState(storageEngine.get('user') || {});

    const notifications = useSelector((state) => state.notifications || []);

    const notificationsList = notifications.filter((each) => each.type === 'error');
    const messagesList = notifications.filter((each) => each.type === 'info' || each.type === 'success');

    const { t } = useTranslation();

    const { ufe_plan: plan } = authenticatedUser;

    const isBasicPlan = plan === 'free' || plan === STARTUP_PLAN;

    const getBadgeData = (type) => {
        if (notificationsList.find((each) => each.type === 'error' && !each.read && !each.resolved)) {
            const newList = notificationsList.filter((each) => each.type === 'error' && !each.read && !each.resolved);
            return type === 'count' ? newList.length : 'red';
        }
        const list = notifications.filter(
            (each) => !each.read && (each.type === 'error' || each.type === 'info' || each.type === 'success')
        );
        return type === 'count' ? list.length : '#52c41a';
    };

    return (
        <div key="headeroptions" className="header-options ufe-center">
            {/* <Link to="/upgrade">
                <Button type="primary" className="ufe-btn--lines ufe-btn-auto-height">
                    {t('template.header.upgrade')}
                </Button>
            </Link> */}

            {isBasicPlan && (
                <div className="ufe-notifications ufe-full-center">
                    <Button
                        type="primary"
                        className="ufe-btn--lines ufe-btn-auto-height"
                        onClick={() => {
                            history.push('/upgrade');
                        }}
                    >
                        Upgrade
                    </Button>
                </div>
            )}

            <div className="ufe-notifications ufe-full-center">
                <div>
                    {/* <Badge style={{ backgroundColor: getBadgeData('color') }} count={getBadgeData('count')}> */}
                    <Popover
                        overlayClassName="popover-notification"
                        placement="bottom"
                        content={<Notification />}
                        arrowPointAtCenter
                    >
                        <BellOutlined onClick={() => toggleNotification(!showNotification)} />
                        {/* <Icon onClick={() => toggleNotification(!showNotification)} type="bell" /> */}
                    </Popover>
                    {/* </Badge> */}
                </div>
            </div>
            <div className="ufe-shop-options">
                <Dropdown
                    overlayClassName="popover-notification"
                    placement="bottomCenter"
                    overlay={<UserMenuList isBasicPlan={isBasicPlan} />}
                    arrow
                    destroyPopupOnHide
                >
                    <div className="uLink ufe-full-center">
                        <div
                            className="ufe-full-center"
                            id="user"
                            style={{ flexDirection: 'column', alignItems: 'flex-end', lineHeight: 4.2 }}
                        >
                            <span>{authenticatedUser.shop_owner || ''}</span>
                        </div>
                        <Avatar icon={<UserOutlined />} />
                        {/* <Avatar icon="user" /> */}
                    </div>
                </Dropdown>
            </div>
        </div>
    );
});
