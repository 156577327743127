import React, { lazy, Suspense } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { authenticateUser } from '../authentication/authentication';
import { isRedirectToUpgrade, checkMaxHitReached } from '../../state/actions/orders';
import { UfeLoader as Loader } from '../loader';

// import component for corresponding route
const Dashboard = lazy(() =>
    import(
        /* webpackChunkName: "ufe-dashboard" */
        './../../components/dashboard'
    )
);
const Authenticate = lazy(() => import('../../components/authentication/Authentication'));

const CreateFunnel = lazy(() =>
    import(
        /* webpackChunkName: "ufe-create-funnel" */
        '../../components/create-funnel'
    )
);
const FunnelView = lazy(() =>
    import(
        /* webpackChunkName: "ufe-funnel-view" */
        '../../components/create-funnel/funnel-view'
    )
);
// const CreateFunnel1 = lazy(() =>
//     import(
//         /* webpackChunkName: "ufe-create-funnel" */
//         '../../components/create-funnel-old'
//     )
// );
const FunnelsListing = lazy(() => import('./../../components/funnels-listing'));
const Tutorials = lazy(() => import('./../../components/tutorials'));
const Settings = lazy(() => import('./../../components/settings'));
const Upgrade = lazy(() => import('./../../components/upgrade'));
const customUpgrade = lazy(() => import('./../../components/upgrade/custom-pricing'));
const merchantsRoute = lazy(() => import('./../../components/admin/merchants/merchantsList'));
const adminSettingsRoute = lazy(() => import('./../../components/admin/merchants/admin-settings'));
const merchantDetails = lazy(() => import('../../components/admin/actions'));
const faqAdminRoute = lazy(() => import('./../../components/admin/faq'));
const faqRoute = lazy(() => import('../../components/faqs'));
const upgradeAnalyticsRoute = lazy(() => import('../../components/admin/upgrade-analytics'));
const OnBoarding = lazy(() => import('../../components/create-funnel/on-boarding'));
const ImportFunnels = lazy(() => import('../../components/admin/merchants/import-funnels'));
const ExtendUpgrade = lazy(() => import('./../../components/upgrade/extend'));

const AppRoute = () => {
    const authenticated = authenticateUser();

    // const monthlyCapHitted = checkMaxHitReached(currentMonthCount);
    const redirectToUpgrade = isRedirectToUpgrade();
    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                <PrivateRoute
                    exact
                    authenticated={authenticated}
                    redirectToUpgrade={redirectToUpgrade}
                    path="/"
                    to="/funnel"
                    component={Redirect}
                />
                <PrivateRoute
                    exact
                    authenticated={authenticated}
                    redirectToUpgrade={redirectToUpgrade}
                    path="/dashboard"
                    component={Dashboard}
                />
                <PrivateRoute
                    exact
                    authenticated={authenticated}
                    redirectToUpgrade={redirectToUpgrade}
                    path="/tutorials"
                    component={Tutorials}
                />
                <PrivateRoute
                    exact
                    authenticated={authenticated}
                    redirectToUpgrade={redirectToUpgrade}
                    path="/settings"
                    component={Settings}
                />
                <PrivateRoute
                    exact
                    authenticated={authenticated}
                    redirectToUpgrade={redirectToUpgrade}
                    path="/funnel/create"
                    component={CreateFunnel}
                />
                <PrivateRoute
                    exact
                    authenticated={authenticated}
                    redirectToUpgrade={redirectToUpgrade}
                    path="/funnel/create/onboarding"
                    component={OnBoarding}
                />
                <PrivateRoute
                    exact
                    authenticated={authenticated}
                    redirectToUpgrade={redirectToUpgrade}
                    // path="/funnel/create/:wizard"
                    path="/funnel/create/wizard/:id"
                    component={CreateFunnel}
                />
                {/* <PrivateRoute exact authenticated={authenticated} path="/funnel/create1" component={CreateFunnel1} /> */}
                <PrivateRoute
                    exact
                    authenticated={authenticated}
                    redirectToUpgrade={redirectToUpgrade}
                    path="/funnel/:id"
                    component={CreateFunnel}
                />
                <PrivateRoute
                    exact
                    authenticated={authenticated}
                    redirectToUpgrade={redirectToUpgrade}
                    path="/funnel/edit/:id"
                    component={CreateFunnel}
                />
                <PrivateRoute
                    exact
                    authenticated={authenticated}
                    redirectToUpgrade={redirectToUpgrade}
                    path="/funnel"
                    component={FunnelsListing}
                />
                <PrivateRoute
                    exact
                    authenticated={authenticated}
                    redirectToUpgrade={redirectToUpgrade}
                    path="/funnel/view/:id"
                    component={FunnelView}
                />
                <PrivateRoute exact authenticated={authenticated} path="/upgrade" component={Upgrade} />
                <PrivateRoute exact authenticated={authenticated} path="/upgrade/extend" component={ExtendUpgrade} />
                <PrivateRoute exact authenticated={authenticated} path="/upgrade/offer" component={customUpgrade} />
                <PrivateRoute authenticated={authenticated} path="/merchants" exact component={merchantsRoute} />
                <PrivateRoute authenticated={authenticated} path="/merchants/faq" component={faqAdminRoute} />
                <PrivateRoute authenticated={authenticated} path="/merchant/:id" component={merchantDetails} />
                <PrivateRoute
                    authenticated={authenticated}
                    path="/merchants/funnels/import"
                    component={ImportFunnels}
                />
                <PrivateRoute
                    authenticated={authenticated}
                    path="/merchants/admin-settings"
                    component={adminSettingsRoute}
                />
                <PrivateRoute authenticated={authenticated} path="/faqs" component={faqRoute} />
                <PrivateRoute
                    exact
                    authenticated={authenticated}
                    path="/merchants/upgrade/analytics"
                    component={upgradeAnalyticsRoute}
                />
            </Switch>
        </Suspense>
    );
};

export default AppRoute;
