import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { merge } from 'lodash';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import { isEmpty, storageEngine, couponCode } from '../../common/helper/commonMethods';
import { getSettings, saveCommonSettings, saveChangedSettings } from '../../state';
import { getEventsAndNotifications, updateNotifications } from '../../state/actions/notifications';
import { updateEvents } from '../../state/actions/events';
import { isRedirectToUpgrade } from '../../state/actions/orders';

import { commonSettings } from '../create-funnel/common/dataProvider';
import AppRoute from '../../common/route/appRoute';
import Header from '../template/header';
import { UfeLoader as Loader } from '../../common/loader';
import { FREE_PLAN_SELECTED } from '../../common/constants';
import Footer from '../template/footer';
import BlackListed from './black-listed';
import UpgradeModal from '../upgrade/upgrade-modal';
import Banner from '../upgrade/banner/banner';

import './MainLayout.scss';

const { Content } = Layout;

function MainLayout(props) {
    const dispatch = useDispatch();
    // to fetch notification in every route change
    const location = useLocation();
    // for gettings common settings from db and
    // saving to redux store
    const [loading, setLoading] = useState(true);
    // for showing header
    const [showHeader, setShowHeader] = useState(true);

    const user = storageEngine.get('user') || {};
    const { ufe_plan: ufePlan } = user;
    const { blackListed = false, freePlanSelected, trialStartDate, shop_owner: shopOwner } = user;
    const newUser = storageEngine.get('isNewUser', true, 'session');
    /**
     * Check if plan selected
     */
    const FreePlanSelectedLocalData = storageEngine.get(FREE_PLAN_SELECTED);
    const isPlanSelected = ufePlan !== 'free' || freePlanSelected || FreePlanSelectedLocalData === true;

    // setBlackListed(blackListStatus);

    // redirectiong to upgrade page when it is a new user
    const { location: { search = {}, pathname = [] } = {}, history } = props || {};
    const params = queryString.parse(search);

    let IS_ONBOARDING = false;
    let IS_UPGRADE_PAGE = false;

    /**
     * generate coupon code with firstname
     */
    const trialCoupon = couponCode(shopOwner, 'trial30');

    const showOnboarding = storageEngine.get('showOnboarding');

    if (pathname.includes('/funnel/create/onboarding')) IS_ONBOARDING = true;
    if (pathname.includes('/upgrade')) IS_UPGRADE_PAGE = true;

    if (params.isNewUser === 'true' || params.isNewUser === true) {
        history.push('/upgrade');
    }
    // if new user and plan accepted then redirect to onboarding
    if (!IS_ONBOARDING && showOnboarding && !isRedirectToUpgrade() && isPlanSelected) {
        history.push('/funnel/create/onboarding');
    }
    // for disabling header when pathname is onboarding
    // useEffect(() => {
    //     setShowHeader(() => !IS_ONBOARDING);
    // }, [IS_ONBOARDING]);

    useEffect(() => {
        // get the redirect url
        const redirectTo = storageEngine.get('ufeRedirectTo');

        // if (user.ufe_plan !== 'free' && ((location || {}).pathname || {}) === '/upgrade') {
        //     history.push('/funnel');
        // }
        if (redirectTo) {
            storageEngine.set('ufeRedirectTo', null);
            if (history) history.push(redirectTo);
        }
    }, []);

    useEffect(() => {
        // get common settings
        getSettings().then((settingsFromDB) => {
            if (isEmpty(settingsFromDB)) {
                dispatch(saveCommonSettings({ ...commonSettings }));
                // it is for passing only changed settings to backend
                dispatch(saveChangedSettings({}));
            } else {
                dispatch(saveCommonSettings(merge({}, commonSettings, settingsFromDB)));
                // it is for passing only changed settings to backend
                dispatch(saveChangedSettings(settingsFromDB));
            }
            // clearing local storage values
            // for controlling visibility of bucks add
            storageEngine.unset('showAd');

            setLoading(false);
        });
        // fetch events and notifications
        getEventsAndNotifications().then((data) => {
            const { events = [], notifications = [] } = data || {};
            dispatch(updateNotifications(notifications));
            dispatch(updateEvents(events));
        });
    }, [dispatch]);

    return blackListed ? (
        <BlackListed />
    ) : (
        <>
            {/* <Layout style={{ minHeight: '100vh' }}>
                <Sider collapsible collapsed={false} theme="light">
                    <Header />
                    </Sider>
                <Layout className="site-layout"> */}
            {!trialStartDate && IS_UPGRADE_PAGE && <Banner coupon={trialCoupon} />}
            {!IS_ONBOARDING && !(!isPlanSelected && IS_UPGRADE_PAGE) && <Header />}
            <Content className={`ufe-main-layout ${IS_ONBOARDING ? 'ufe-onboarding-window' : ''}`}>
                {/* {showAnyAlerts} */}
                <div className="ufe-layout">
                    {/* <Loader /> */}
                    {loading ? (
                        <Loader />
                    ) : (
                        <>
                            <AppRoute />
                            <UpgradeModal />
                        </>
                    )}
                </div>
            </Content>
            <Footer />
            {/* <Footer />
                </Layout>
            </Layout> */}
        </>
    );
}

export default withRouter(MainLayout);
