import { UFE_DASHBOARD_SAVE_ANALYTICS } from '../actions';

const initialState = {};
/**
 * Handle dashboard data from redux state
 * @param {*} state  dashboard state from redux store
 * @param {*} action dispatched action details contains `type` and `payload`
 */
export const dashboard = (state = initialState, action) => {
    switch (action.type) {
        // save analytics to redux state
        case UFE_DASHBOARD_SAVE_ANALYTICS:
            return {
                ...state,
                analytics: action.payload,
            };
        default:
            return { ...state };
    }
};
