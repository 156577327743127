/**
 * This file is responsible for holding the default settings of the widget
 * This common file is accessed in both widgets sdk and client
 */

// IMPORTANT: this selectors are used in both jquery and vanilla js document.querySelector
// so jquery specific class like .someclass:first will not be recognized in vanilla js selector
const productPageTriggers =
    "form[action*='/cart/add'] button[name='add'],form[action*='/cart/add'] button[type='submit'],form[action*='/cart/add'] input[name='add'],form[action*='/cart/add'] button[name='checkout'],form[action*='/cart/add'] input[type='submit'], form[action*='/cart/add'] #add-to-cart,form[action*='/cart/add'] .addtocart-js";

export const cartPageForm = "form[action*='/cart'], form[action*='/checkout'], form[action*='/a/checkout']";

export const GO_TO_CART = 'GO_TO_CART';

const settings = {
    general: {
        freeShipping: false,
        immediateTrigger: false,
        popupDelay: 0,
        allowQuantitySelector: false,
        timerStatus: false,
        timer: {
            status: false,
            expiryAction: 'hideTimer',
            timer: '03:00',
        },
        timerExpiryAction: 'hideTimer',
        upsellType: 'normal',
        upsellStyle: 'popup',
        integrateCustomerAddress: false,
        rejectActionOnClose: false,
        skipCart: true,
        validity: null,
        hideAtcForSingleOffer: true,
        hideDiscountBox: true,
        hideMiniCart: false,
        requireSourceProduct: true,
        upsellAlreadyInCart: 'none', // enum:['none', 'hideWidget','hideProducts']
        addProductLink: false,
        fbtAllChecked: true,
        hideCheckoutBtn: false,
        addTriggerAlongWithUpsell: true,
        comparePriceBehaviour: 'shopifySalePrice', // enum:['shopifySalePrice','shopifyComparePrice']
        rejectButtonBehaviour: null, // enum ['goToCart','goToCheckout','continueShopping']
        mostPopularPreSelection: true, //  for volume discount upsells,
        hideOutOfStockProducts: true,
        upgradeProduct: false,
        additionalScripts: null,
        validateProductForm: false,
        reloadOnQtyChange: true,
        showCustomDescription: false,
        showShopifyDefaultDiscount: true,
        goToCartAction: 'goToCart', // enum ['goToCart','continueShopping']
    },
    advanced: {
        discountApplyMethod: 'draftOrder',
        discountStacking: false,
    },
    translations: {
        upsellTitle: 'Congrats!! 🎉 Special Discounts Unlocked! 🎁',
        acceptBtn: 'Yes, I want',
        rejectBtn: 'No, thanks',
        deleteBtn: 'Delete',
        floatingBtn: 'Reveal Offers',
        addQuantityBtn: 'Add Quantity',
        addVariantBtn: 'Add Variant',
        closeBtn: 'Close →',
        // next prev offer
        nextOfferBtn: 'Next Offer →',
        prevOfferBtn: '← Previous Offer',
        checkoutBtnText: 'Checkout',
        saveText: 'SAVE {{DISCOUNT}}',
        savedText: 'SAVED',
        bundleText: 'BUNDLE OFFER',
        cartText: 'Cart',
        shippingAddressText: 'Shipping Address',
        mostPopularText: 'Most Popular',
        totalText: 'Total',
        nextBtnText: 'Next',
        eachItemText: 'Each Item',
        generatingOfferText: 'Generating Your Offer',
        redirectToCartText: 'Redirecting to cart',
        timerText: 'Offer Ends in {{TIMER}}',
        quantitiesText: '{{QUANTITY}} Quantities',
        freeShipping: 'FREE! Shipping',
        free: 'Free',
        goToCartBtn: 'Go to cart', // enum ['Go to cart','Add & Go to cart']
        discountCodeLabel: 'Discount Code',
        discountCodePlaceholder: 'Enter discount code',
        discountCodePreviewText: 'Have a discount code?',
        discountCodeButtonText: 'Apply',
        discountCodePrefix: 'Discount Code:',
        cartPageSavedText: 'Saved {{DISCOUNT}}',
        checkoutPageNormalOffer: 'Discounted Offer {{DISCOUNT}} Off',
        checkoutPageBundleOffer: 'Bundle Offer {{DISCOUNT}} Off',
        checkoutPageVolumeDiscountOffer: 'Bulk Buy Offer {{DISCOUNT}} Off',
        checkoutPageFbtOffer: 'Bought Together Offer {{DISCOUNT}} Off',
        checkoutPageFreeShipping: 'Free Shipping',
        upgradeProduct: 'Upgrade',
        viewMoreButton: 'View More',
    },
    design: {
        template: 'ufeModern',
        theme: 'default',
        font: 'Inherit From Store',
        imageView: 'contain', // ['cover','contain']
        colors: {
            topBarColor: '#197cff',
            secondary: '#83F8FF',
            acceptBtnColor: '#197cff',
            rejectBtnColor: '#fff',
            checkoutBtnColor: '#272727',
            deleteBtn: '#ff5a5a',
            floatingBtn: '#8619ff',
            salePriceColor: '#52c41a',
            comparePriceColor: '#ccc',
            saveBadge: 'transparent',
            bundleBadge: '#484848',
            mostPopularColor: '#252525',
            freeShippingColor: '#5dc181',
            nextPrevBtnColor: '#fff',
            mostPopularCard: '#ffdb75',
            offerDisplayCard: '#fff',
            popupBgColor: 'transparent',
            addressFormBgColor: '#666',
            timer: '#197cff',
            discountBoxBackground: '#666666',
            discountBoxText: '#ffffff',
            discountApplyBtn: '#757575',
        },
        additionalCSS: '',
    },
    floatingPosition: 'bottomRight',
    position: {
        productPage: `form[action*='/cart/add']`,
        cartPage: `header`,
        thankyouPage: `.main__content .step .section:first-child`,
        homePage: 'body',
        ordersPage: '.main__content .step .section:first-child',
        collectionPage: 'body',
        discountBox:
            "button[name='checkout'], input[name='checkout'], button[name*='checkout'], [name*='checkout'], [name*='check-out'], [href^='/checkout'], form[action*='/checkout'] button[type='submit'], form[action*='/checkout'] input[type='submit']",
        discountBoxPositionType: 'before',
        cartFormSelector: 'form[action*="/cart"]',
        productFormSelector: 'form[action*="/cart/add"]:first',
    },
    inpagePositionType: 'after', // ['after','before'],
    trigger: {
        productPage: productPageTriggers,
        cartPage:
            "button[name='checkout'], input[name='checkout'], button[name*='checkout'], [name*='checkout'], [name*='check-out'], [href^='/checkout'], form[action*='/checkout'] button[type='submit'], form[action*='/checkout'] input[type='submit']",
        thankyouPage: '',
        homePage: productPageTriggers,
        ordersPage: '',
        collectionPage: '',
    },
    tracking: {
        storeDefaultFbPixel: true,
        storeDefaultGoogleAnalytics: true,
    },
    widgetSpecific: {
        volumeDiscount: {
            allowVariantSelection: true,
            addDefaultTriggerProductAsVolumeDiscount: {
                addAsVolumeDiscount: true,
                acceptAction: GO_TO_CART,
            },
            redirectToCartPage: false,
        },
        bundle: {
            hasIndividualBundleDiscount: false,
            bundleWithTriggerProduct: true,
        },
        fbt: {
            includeTriggerProductInFbt: false,
        },
    },
    checkout: {
        addOrderTag: true,
        orderTagName: 'ufe_order',
    },
    integrations: {
        hasSlideCart: false,
    },
};

export default settings;
