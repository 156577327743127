import { CHANGE_UPSELL } from '../actions/previewIframe';

export const previewIframe = (state = {}, action) => {
    switch (action.type) {
        case CHANGE_UPSELL:
            return {
                ...state,
                upsell: { ...action.payload },
            };
        default:
            return state;
    }
};
