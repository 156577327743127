import { OPEN_UPGRADE_MODAL, CLOSE_UPGRADE_MODAL } from '../actions';

const initialState = {
    show: false,
};

export const upgradeModal = (state = initialState, action) => {
    const { type, url, payload } = action;
    switch (type) {
        case OPEN_UPGRADE_MODAL:
            return {
                ...state,
                show: true,
                url,
            };
        case CLOSE_UPGRADE_MODAL:
            return {
                ...state,
                show: false,
                url,
            };
        default:
            return { ...state };
    }
};
