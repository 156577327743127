/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, Row, Col, Button, Typography } from 'antd';
import { upgradeModalStatus, upgradeModalURL } from '~state/selectors/upgradeModal';
import { closeUpgradeModal } from '~state/actions';
import http from '../../../common/http/httpProvider';
import Premium from './premium';
import PayPerUse from './pay-per-use';

import './upgrade-modal.scss';

const { Title } = Typography;

const SHOW_UNLIMITED_PLAN = true;
const SHOW_TIMER_IN_UNLIMITED_PLAN = true;
const OFFER_PRICE = 7.99;
const FIXED_PRICE = 29.99;
const PAY_PER_USE_PRICE = 7.99;
const TIMER_EXPIRY = 300000;

export default withRouter(function ({ history }) {
    const dispatch = useDispatch();
    // modal state
    const [showModal, setShowModal] = useState(false);
    const [modalURL, setModalURL] = useState(false);
    const [showOfferPrice, setShowOfferPrice] = useState(false);
    const [time, setTime] = useState(TIMER_EXPIRY);
    const showModalFromRedux = useSelector(upgradeModalStatus);
    const youtubeUrlModalFromRedux = useSelector(upgradeModalURL);

    const timer = setTimeout(() => {
        setShowOfferPrice(false);
    }, TIMER_EXPIRY);

    /**
     * on clicking upgrade
     */
    const onUpgrade = () => {
        dispatch(closeUpgradeModal());
        /**
         * saving log
         */
        http.postAction('api/v1/billing/upgrade/log', { action: 'upgradeToUnlockClick' })
            .then((res) => {
                console.log('logged');
            })
            .catch((err) => {
                console.log('error in loging');
            });
        history.push('/upgrade');
    };

    // useEffect(() => {
    //     let myInterval = null;
    //     if (SHOW_TIMER_IN_UNLIMITED_PLAN) {
    //         /**
    //          * for timer countdown
    //          */
    //         myInterval = setInterval(() => {
    //             if (time > 0) {
    //                 setTime((currTime) => currTime - 1000);
    //             }
    //         }, 1000);
    //     }
    //     return () => {
    //         if (SHOW_TIMER_IN_UNLIMITED_PLAN) {
    //             clearInterval(myInterval);
    //         }
    //     };
    // });

    // // register redux
    useEffect(() => {
        setShowModal(showModalFromRedux);
        setModalURL(youtubeUrlModalFromRedux);
        // checking all constants
        // if (SHOW_TIMER_IN_UNLIMITED_PLAN) {
        //     setShowOfferPrice(true);
        //     setTime(TIMER_EXPIRY);
        // }

        /**
         * sending upgrade logs
         */
        if (showModalFromRedux) {
            http.postAction('api/v1/billing/upgrade/log', { action: 'upgradeToUnlockView' })
                .then((res) => {
                    console.log('logged');
                })
                .catch((err) => {
                    console.log('error in loging');
                });
        }
    }, [showModalFromRedux]);

    // close modal
    const onClose = () => {
        dispatch(closeUpgradeModal());
    };

    return (
        <Modal
            width="800px"
            className="ufe-upgrade-to-unlock"
            visible={showModal}
            footer={null}
            onCancel={onClose}
            closable
            zIndex={1500}
        >
            {/* {SHOW_UNLIMITED_PLAN && (
                <Row>
                    <Premium
                        time={time}
                        fixedPrice={FIXED_PRICE}
                        offerPrice={OFFER_PRICE}
                        showOfferPrice={showOfferPrice}
                        showTimer={SHOW_TIMER_IN_UNLIMITED_PLAN}
                    />
                </Row>
            )}
            <Row>
                <PayPerUse payPerUsePrice={PAY_PER_USE_PRICE} />
            </Row> */}
            <Title level={2}>Upgrade to a Paid Plan to Unlock this Feature!</Title>
            <iframe
                width="100%"
                height="400"
                src={modalURL}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
            <div className="ufe-upgrade-content">
                <span className="ufe-upgrade-content-text">
                    You must be on a paid plan (either Without Watermark Plan or Unlimited plan) to use this feature!
                </span>
                <Row justify="center">
                    <Button className="ufe-btn-bold ufe-btn--blue" type="primary" onClick={onUpgrade}>
                        Upgrade
                    </Button>
                </Row>
            </div>
        </Modal>
    );
});
