import { UPDATE_NOTOFICATIONS } from '../actions/notifications';

export const notifications = (state = [], action) => {
    switch (action.type) {
        case UPDATE_NOTOFICATIONS:
            return [...action.payload];
        default:
            return [...state];
    }
};
