const BILLING_TABLE = [
    {
        start: 0,
        end: 50,
        price: 0,
    },
    {
        start: 51,
        end: 100,
        price: 7.99,
    },
    {
        start: 101,
        end: 200,
        price: 19.99,
    },
    {
        start: 201,
        end: 500,
        price: 29.99,
    },
    {
        start: 501,
        end: 1000,
        price: 49.99,
    },
    {
        start: 1001,
        end: 1700,
        price: 79.99,
    },
    {
        start: 1701,
        end: 2600,
        price: 99.99,
    },
    {
        start: 2601,
        end: 3600,
        price: 129.99,
    },
    {
        start: 3601,
        end: 5000,
        price: 159.99,
    },
    {
        start: 5001,
        end: 10000,
        price: 189.99,
    },
    {
        start: 10001,
        end: 30000,
        price: 199.99,
    },
    {
        start: 30001,
        end: 60000,
        price: 229.99,
    },
    {
        start: 60001,
        end: 100000,
        price: 249.99,
    },
    {
        start: 100001,
        end: null,
        price: 249.99,
    },
];

module.exports = BILLING_TABLE;
