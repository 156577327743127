// import 'react-hot-loader';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import ErrorBoundary from './common/authentication/errorBoundary';

import App from './App';
// import * as serviceWorker from './serviceWorker';
import { store } from './state';
import i18n from './i18n';

// prefix REACT_APP_ the keyname in .env file
if (process.env.NODE_ENV !== 'development')
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        release: process.env.SENTRY_RELEASE_KEY,
    });

console.log(`release`, process.env.SENTRY_RELEASE_KEY);
console.log(`dsn`, process.env.REACT_APP_SENTRY_DSN);

i18n.init().then(() =>
    // eslint-disable-next-line react/no-render-return-value
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <ErrorBoundary>
                    <I18nextProvider i18n={i18n}>
                        <App />
                    </I18nextProvider>
                </ErrorBoundary>
            </BrowserRouter>
        </Provider>,
        document.getElementById('root')
    )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
