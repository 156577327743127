/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
// import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect } from 'react';

import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
// import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getUser, getToken, authenticateUser } from './common/authentication/authentication';
import { storageEngine, verifyPlan, isEmpty, clearAllLocalData } from './common/helper/commonMethods';
import Authentication from './components/authentication';
import http from './common/http/httpProvider';
import { globalState } from './common/helper/globalState';
import { getOrdersCount, ORDERS_COUNT, saveLastThirtyDaysOrdersCount } from './state/actions/orders';

// Extra Widgets
import {
    initChatWidget,
    initGoogleTagManager,
    initHotjarAnalytics,
    initRedditPixel,
    updateChatWidget,
} from './utils/widgets';
import { initLiveChat, triggerMessage } from './utils/crispChat';
import { HELP_MESSAGE } from './common/constants';
// overrides
import './styles/antdOverrides/antd.less';

import './App.scss';
import './styles/scss/common.scss';
import './commonStyles.scss';

const BETAACCESS_URL = 'api/v1/migration/beta';
function App(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    // assign translation reference to a public object, inorder to use translation in non react functional components.
    globalState.translate = t;
    const [authenticated, setAuthenticated] = useState(false);
    const [authenticating, setAuthenticating] = useState(true);

    /**
     * get mix panel instance
     */
    const { mixpanel = {} } = window || {};

    const isMobileDevice = () => {
        const mobileUserAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        );
        const mobileScreenSize = window.screen.width < 720;
        return mobileUserAgent && mobileScreenSize;
    };

    const getUserDetails = (token, isNewUser) => {
        getUser(token, (data) => {
            setAuthenticated(!!data);
            try {
                // initChatWidget(data);
                if (!isEmpty(data) && process.env.NODE_ENV !== 'development') {
                    /**
                     * initilize crisp only if $crisp is does already initialized
                     *  */
                    const { $crisp = {} } = window;
                    if (typeof $crisp.push !== 'function')
                        initLiveChat(data, () => {
                            triggerMessage(HELP_MESSAGE, data);
                        });
                    initGoogleTagManager();
                    initHotjarAnalytics();
                    initRedditPixel();
                    if (isNewUser) {
                        if (data.last_installed && new Date(data.last_installed) > new Date(data.first_installed))
                            mixpanel.track && mixpanel.track('Reinstall Install');
                        else mixpanel.track && mixpanel.track('Install event');
                    } else mixpanel.track && mixpanel.track('User Access event');
                }
            } catch (err) {
                console.log('process.env error');
            }
            if (data) {
                // fetching orders count
                getOrdersCount().then((count) => {
                    storageEngine.set(ORDERS_COUNT, count);
                    dispatch(saveLastThirtyDaysOrdersCount(count));
                    /**
                     * updating user property in fresh chat widgets
                     */
                    if (process.env.NODE_ENV !== 'development') {
                        // window.CRISP_READY_TRIGGER = function () {
                        setTimeout(() => {
                            const { $crisp } = window;
                            $crisp && $crisp.push(['set', 'session:data', [[['totalOrders', count]]]]);
                        }, 10000);
                        /**
                         * Crisp chat instance
                         */
                        // };
                    }
                });
            }
            setAuthenticating(false);
        });
    };

    useEffect(() => {
        const handleResize = () => {
            const isMobile = isMobileDevice();
        };
        const authenticate = (params = {}) => {
            // fetch token from url or from local storage.
            params.token = params.token ? params.token : getToken();
            params.isNewUser = params.isNewUser === 'true';

            storageEngine.set('isNewUser', params.isNewUser, 'session');

            if (params.isNewUser === 'true' || params.isNewUser === true) {
                storageEngine.set('showOnboarding', true);
            }

            http.setAuthorizationToken(params.token);
            if (!params.token) {
                // if user is not authenticated and token is not present, set authenticating value as false.
                setAuthenticating(false);
            } else {
                // token is absent in localStorage and present in url, place an api call to authenticate user with specified token.
                getUserDetails(params.token, params.isNewUser);
                // if (params && params.betaAccess) {
                // http.postAction(BETAACCESS_URL, {}).then({});
                // }
            }
        };

        const parameters = queryString.parse(props.location.search);
        clearAllLocalData();
        authenticate(parameters);
        // code here to handle componentWillUnMount
        console.log('App component is rendering');
        window.addEventListener('resize', handleResize);
        // eslint-disable-next-line react/destructuring-assignment

        // eslint-disable-next-line react/destructuring-assignment
    }, [dispatch, props.location.search.token, props.location.search.isNewUser]);

    return (
        <>
            <Authentication authenticated={authenticated} authenticating={authenticating} />
        </>
    );
}

// export default hot(withRouter(App));
export default withRouter(App);
