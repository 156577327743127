/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { LogoutOutlined, BorderOuterOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { storageEngine } from '../../../../../common/helper/commonMethods';

export default withRouter(function ({ isBasicPlan }) {
    const user = storageEngine.get('user') || {};
    const { ufe_plan: ufePlan, isDev, myshopify_domain, token } = user || {};
    const { t } = useTranslation();

    const getUserMenuList = useCallback(() => {
        const billingOption = (
            <Menu className="ufe-user-dropdown-menu">
                {!isBasicPlan && (
                    <Menu.ItemGroup title="Billing">
                        <Menu.Item key="1" icon={<BorderOuterOutlined />}>
                            <NavLink to="/upgrade" key="upgrade">
                                Plan & Usage
                            </NavLink>
                        </Menu.Item>
                    </Menu.ItemGroup>
                )}

                <Menu.ItemGroup title="Account">
                    {isDev && (
                        <Menu.Item key="2">
                            <a href={`/api/v1/admin-dev/access?dev=1&back=1&token=${token}`}>
                                <ArrowLeftOutlined />
                                {t('template.header.backToDev')}
                            </a>
                        </Menu.Item>
                    )}
                    <Menu.Item key="3" icon={<ArrowLeftOutlined />}>
                        <a href={myshopify_domain ? `//${myshopify_domain}/admin` : ''}>
                            {t('template.header.backToShopify')}
                        </a>
                    </Menu.Item>
                    <Menu.Item key="4" icon={<LogoutOutlined style={{ color: '#ff4d4f' }} />}>
                        <a href="/api/v1/logout" style={{ color: '#ff4d4f' }}>
                            {t('template.header.logout')}
                        </a>
                    </Menu.Item>
                </Menu.ItemGroup>
            </Menu>
        );
        return billingOption;
    }, [t, isDev, myshopify_domain, isBasicPlan, token]);

    return getUserMenuList();
});
