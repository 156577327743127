/* eslint-disable no-empty */
const API_URL = {
    // url: 'https://ufe-app-stage-rp7todhvra-uc.a.run.app/',
    url: '/',
    // process.env.server === 'local' ? '/' : 'https://ufe-app-2.localtunnel.me/'
};

try {
    if (JSON.parse(process.env.REACT_APP_SEPERATE_API_DOMAIN)) {
        if (process.env.REACT_APP_BUILD_ENV === 'staging') API_URL.url = process.env.REACT_APP_STAGING_PUBLIC_API_URL;
        if (process.env.REACT_APP_BUILD_ENV === 'production')
            API_URL.url = process.env.REACT_APP_PRODUCTION_PUBLIC_API_URL;
    }
} catch (err) {}

console.log('build env', process.env.BUILD_ENV);
console.log('build env', process.env.REACT_APP_SEPERATE_API_DOMAIN);

export default API_URL;
