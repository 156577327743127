export const UFE_LOADING = 'UFE_LOADING';
export const UFE_LOADING_SUCCESS = 'UFE_LOADING_SUCCESS';

export const ufeLoading = () => ({
    type: UFE_LOADING,
});

export const afterLoadingSuccess = () => ({
    type: UFE_LOADING_SUCCESS,
});
