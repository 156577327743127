/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import isSupportHours from '~common/helper/isSupportHours';
import './banner.scss';

function Banner({ coupon }) {
    /**
     * get mix panel instance
     */
    const { mixpanel = {} } = window || {};

    const bannerClick = () => {
        // eslint-disable-next-line no-unused-expressions
        mixpanel.track && mixpanel.track('banner click');
    };
    const showFakeCouponInWorkingHours = () => {
        const supportHours = isSupportHours();
        if (supportHours) return 'NEWUSER21';
        return coupon;
    };
    return (
        <div className="ufe-upgrade-banner" onClick={bannerClick}>
            Congrats! Grab a 21-day FREE trial for any paid plan using
            <div
                style={{ fontWeight: '700', margin: '0 4px', display: 'inline' }}
            >{` '${showFakeCouponInWorkingHours()}' `}</div>{' '}
            coupon code.
        </div>
    );
}

export default Banner;
