import React from 'react';
import './UfeLoader.scss';

export const UfeLoader = (props) => {
    const { type = 'full', className, size = 'large' } = props;
    return (
        <div
            className={`${type === 'full' ? 'pre-uloader' : 'ufe-loader--static'} uloader--${size} ${className || ''}`}
        >
            <div className="uloader" />
        </div>
    );
};
