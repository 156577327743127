import { UFE_SAVE_COMMON_SETTINGS } from '../actions';

const initialState = {};
/**
 * Handle settings data from redux state
 * @param {*} state  settings state from redux store
 * @param {*} action dispatched action details contains `type` and `payload`
 */
export const settings = (state = initialState, action) => {
    switch (action.type) {
        case UFE_SAVE_COMMON_SETTINGS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};
