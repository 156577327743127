/* eslint-disable no-unused-expressions */
/* eslint-disable no-empty */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
// generate short string.
import { transform, isEqual, isObject, unset } from 'lodash';
import dayjs from 'dayjs';
import { faqCategories } from '~common/data-provider/dataProvider';
import { FREE_PLAN_SELECTED, TRIAL_DAYS } from '../constants';
import BILLING_TABLE from './billingTable';

export const uuId = (length = 10) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const capitalize = (word) => {
    if (typeof word !== 'string') return '';
    return word.charAt(0).toUpperCase() + word.slice(1);
};
// find days ago

export const daysAgo = (sDate) => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const TODAY = new Date();
    return Math.round(Math.abs((TODAY - sDate) / oneDay));
};
export const getFirstName = (name) => (name || '').split(' ')[0];

export const couponCode = (shopOwner, type) => {
    const firstName = getFirstName(shopOwner).toUpperCase();
    switch (type) {
        case 'trial30':
            return `${firstName}_30_SPECIAL_TRIAL`;
        default:
            return '';
    }

    // const coupon10OFF = `${firstName}10`;
};

export const sort = (list, property) => [...list.sort((a, b) => (a[property] > b[property] ? 1 : -1))];

// create as a common fn.
export const debounce = (fn, delay) => {
    let timerId;
    return (...args) => {
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            fn(...args);
            timerId = null;
        }, delay);
    };
};

/* Check if an object is empty */
export const isEmpty = (a) => (a ? Object.keys(a || {}).length === 0 : true);

/*
 *
 * Instead of doing this
 *   - Obj && Obj.funnel && Obj.funnel.trigger && Obj.funnel.trigger.product && {}
 * Call this
 *   - Obj(Obj, 'Obj.funnel.trigger.product') returns the values of Obj.funnel.trigger.product if not empty
 *     otherwise return false
 */
export const getIt = (obj, key, defaultValue = null) => {
    if (!obj) {
        return defaultValue;
    }
    const result = key.split('.').reduce(
        (o, x) =>
            /* If a exists then checking a[b] exists or a[b] equals zero, because in some cases the value will be 0 which not equals false, but javascript treats it as false */
            o && (o[x] || o[x] === 0) ? o[x] : null,
        obj
    );

    /*
     * If (result is null and emptyArray needed) then
     *       return []
     *   otherwise (result is empty array and emptyArray is not needed) then
     *       return null
     *   otherwise return result
     */
    // return (needEmptyArray && (result == null)) ? [] : ((Array.isArray(result) && result.length === 0) && !needEmptyArray) ? null : result;
    // console.log('res', JSON.stringify(result) );
    // return result ? (defaultValue ? ((Array.isArray(result) && result.length === 0) ? defaultValue : result) : true) : defaultValue;
    return result || result === 0
        ? Array.isArray(result) && result.length === 0
            ? defaultValue
            : defaultValue !== null
            ? result
            : true
        : defaultValue;
};
// delete a propert from an object
export const unsetProperty = (object, path) => {
    const pathString = path.join('.');
    unset(object, pathString);
};
export const formatCurrency = (format, money) =>
    (format || '').replace(/(<([^>]+)>)/gi, '').replace(/({{([^}]+)}})/gi, money.toFixed(2));

export const storageEngine = {
    // TODO: whether to need null check
    set(key, val, storeType = 'localStorage') {
        (storeType !== 'localStorage' ? sessionStorage : localStorage).setItem(key, JSON.stringify(val));
    },
    get(key, convertToJson = true, storeType = 'localStorage') {
        const item = (storeType !== 'localStorage' ? sessionStorage : localStorage).getItem(key);
        let convertJson = item;
        try {
            convertJson = JSON.parse(item);
        } catch (error) {}
        return convertToJson ? convertJson : item;
    },
    unset(key, storeType = 'localStorage') {
        !Array.isArray(key) && (key = [key]);
        key.forEach((k) => {
            (storeType !== 'localStorage' ? sessionStorage : localStorage).removeItem(k);
        });
    },
};

// extract youtube video id from url.
export const getYoutubeVideoId = (url = ' ') => {
    // check wheather the url belongs to youtube
    const urlRegx = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm;
    if (url.match(urlRegx)) {
        console.log('url is correct => ', url);
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return match && match[2].length === 11 ? match[2] : null;
    }
    return null;
};

/**
 * clone an object or array.
 * @param {*} input can be array or object
 */
export const deepClone = (input) => JSON.parse(JSON.stringify(input || {}));

/**
 * Group by property name
 * @param {*} data array of elements
 * @param {*} key property name to be grouped
 */
export const groupBy = function (data, key) {
    return (data || []).reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};
/**
 * find an object in an object array by using a key and a value
 * @param {*} object array
 * @param {*} key
 * @param {*} value
 */
export const findObjectByKey = (array, key, value) => {
    for (let i = 0; i < array.length; i++) {
        if (array[i][key] === value) {
            return array[i];
        }
    }
    return null;
};

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const objectDifference = (object, base) =>
    transform(object, (result, value, key) => {
        if (!isEqual(value, base[key])) {
            result[key] = isObject(value) && isObject(base[key]) ? objectDifference(value, base[key]) : value;
        }
    });

// checking user plan
export const verifyPlan = (plan, advanced = false) => {
    if (advanced) {
        return plan && !plan.match(/^(affiliate|partner_test|staff_business|staff|trial)$/);
    }

    return plan && !plan.match(/^(affiliate|partner_test|staff_business|staff|shopify_plus|trial)$/);
};

export const createUUID = () =>
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    `_${Math.random().toString(36).substr(2, 9)}`;

export const getRandomNumber = (max) => {
    const index = Math.floor(Math.random() * max) + 1;
    return index;
};

export const getFaqCategory = (value) => {
    const category = faqCategories.find((cat) => cat.value === value);
    return category.label;
};
// calculate interval bw shop created date and today
const getActiveDays = (createdAt) => {
    const createdDate = new Date(createdAt);
    const today = new Date();

    const differenceTime = today.getTime() - createdDate.getTime();
    const difference = differenceTime / (1000 * 3600 * 24);
    return difference;
};

export const getPriceBasedOnOrderCount = (orderCount) =>
    (BILLING_TABLE || []).find((billingPlan) => +orderCount > billingPlan.start && +orderCount < billingPlan.end);

export const getPrice = (
    planName,
    orderCount,
    prioritySupport = false,
    pricingAmount = null,
    createdAt,
    isNovemberPlanAvailable
) => {
    const dateFromCreated = getActiveDays(createdAt);

    let price = 0;
    if (pricingAmount && pricingAmount > 0) {
        price = pricingAmount;
    } else {
        switch (planName) {
            case 'professional':
                if (isNovemberPlanAvailable) {
                    price = 29.99;
                } else if (dateFromCreated > 365) {
                    price = 29.99;
                } else if (orderCount <= 50) {
                    price = 19.99;
                } else if (orderCount <= 100) {
                    price = 24.99;
                } else {
                    price = 29.99;
                }
                break;
            case 'unlimited':
                price = 39.99;
                break;
            case 'shopify_plus':
                price = 59.99;
                break;
            default: {
                if (isNovemberPlanAvailable) {
                    price = 19.99;
                } else if (dateFromCreated > 365) {
                    price = 19.99;
                } else if (orderCount <= 50) {
                    price = 14.99;
                } else if (orderCount <= 100) {
                    price = 17.99;
                } else if (orderCount > 350) {
                    price = 29.99;
                } else {
                    price = 19.99;
                }
                // price = orderCount > 350 ? 39.99 : 29.99;
            }
        }
        const expectedPrice = getPriceBasedOnOrderCount(orderCount) || {};
        const discountedPrice = +expectedPrice.price - (+expectedPrice.price * 30) / 100;
        price = price > (discountedPrice || 0) ? price : discountedPrice || 0;
    }
    return (price + +(prioritySupport ? 10 : 0)).toFixed(2);
};

// export const getDiscountedPrice = (price) => {
//     const discountedPrice = (price - 10).toFixed(2);
//     if (discountedPrice > 0) return discountedPrice;
//     return 0.0;
// };
export const getDiscountedPrice = (price, coupon) => {
    let discountedPrice = price;
    switch (coupon) {
        case 'BFCM20':
            // eslint-disable-next-line no-param-reassign
            discountedPrice = (discountedPrice - (discountedPrice * 20) / 100).toFixed(2);
            break;

        default:
            break;
    }
    return discountedPrice;
};

// export const getTrailDays = () => {
//     const today = Date.now();
//     const trialEnds = new Date(2020, 9, 21);
//     const oneDay = 24 * 60 * 60 * 1000;
//     const diffDays = Math.round(Math.abs((trialEnds - today) / oneDay));

//     if (diffDays < 7) return 7;
//     return diffDays;
// };

export const getRemainingDays = (trialLeft, firstInstalled, trialStartDate, lastInstalled, trialPeriod = 7) => {
    const firstInsalledDate = new Date(firstInstalled);
    const maximumTrialDate = new Date(2020, 9, 16);
    // check if the user is old user or not
    // if (firstInsalledDate.getTime() < maximumTrialDate.getTime()) {
    //     return 0;
    // }
    const todaysDate = new Date();
    if (trialLeft !== undefined) {
        const remaining = todaysDate.getTime() - new Date(lastInstalled).getTime();
        const days = remaining / (1000 * 3600 * 24);
        return parseInt(days >= trialLeft ? 0 : trialLeft - days);
        // const trialRemaining = dayjs(lastInstalled).add(trialLeft, 'day').diff(dayjs()).days();
        // return trialRemaining >= 0 ? trialRemaining : 0;
    }
    // it is last priced accepted date string
    if (trialStartDate) {
        const remaining = todaysDate.getTime() - new Date(trialStartDate).getTime();
        const days = remaining / (1000 * 3600 * 24);
        return parseInt(days >= trialPeriod ? 0 : trialPeriod - days);
    }
    // check is user already in trial
    // if id in trial show remaining days or 0 even trial ends
    const installedDate = lastInstalled ? dayjs(lastInstalled) : dayjs(firstInstalled);
    // const dayDiff = dayjs().diff(installedDate, 'day');
    // const remainingDaysNormalized = dayDiff >= TRIAL_DAYS ? 0 : TRIAL_DAYS - dayDiff;
    return TRIAL_DAYS;
};

// clear all localstorage data when app boots up
export const clearAllLocalData = () => {
    storageEngine.unset(FREE_PLAN_SELECTED);
    storageEngine.unset('crispTokenLocal');
    storageEngine.unset('isFirstMessageSent');
    storageEngine.unset('crispTriggerFired');
};
