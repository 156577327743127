export const DEFAULT_IMAGE = '';
export const TRIAL_DAYS = 7;
export const TRIAL_COUPON_PERIOD = 30;

// local storage keys

export const FREE_PLAN_SELECTED = 'selected_free_plan';
export const STARTUP_PLAN = 'startup_plan';
export const PAY_PER_USE_PLAN = 'payPerUse';
export const UNLIMITED_PLAN = 'unlimited';

export const DUMMY_TRIAL_COUPON = 'NEWUSER21';
export const STARTUP_STORE_AGE = 182;

export const AVAIL_STARTUP_PLAN = 'AVAIL STARTUP PLAN';
export const OPEN_CHAT = 'OPEN CHAT WINDOW';
export const SENT_QUERY = 'SENT QUERY';
export const HELP_MESSAGE = 'SHOW HELP TRIGGER';

export const ADMIN_SETTINGS_APPLIED = 'adminSettingsApplied';
export const CUSTOM_DESCRIPTION_YOUTUBE = 'https://www.youtube.com/embed/HuBbMfHSWUM';
export const PREMIUM_THEMES_YOUTUBE = 'https://www.youtube.com/embed/tgxdlI2k_gE';
export const ENCRYPTION_PASSWORD = 'UDE8H8HE80EF0E9FJE093NF30FSA';
