// ufe plans
export const ufeStatus = [
    { text: 'Active', value: true },
    { text: 'Uninstalled', value: false },
];
// ufe plans
export const ufePlans = [
    { text: 'Free', value: 'free' },
    { text: 'Pay Per Use', value: 'payPerUse' },
    { text: 'Unlimitted', value: 'unlimitted' },
];
// plans
export const plans = [
    { text: 'shopify_plus', value: 'shopify_plus' },
    { text: 'unlimited', value: 'unlimited' },
    { text: 'advanced', value: 'advanced' },
    { text: 'basic', value: 'basic' },
    { text: 'partner_test', value: 'partner_test' },
    { text: 'Professional', value: 'professional' },
];

export const ufePricePlans = [
    {
        key: 1,
        totalStoreOrder: '0 - 50',
        lowerBound: 0,
        upperBound: 50,
        features: 'Powered by watermark',
        monthlyPrice: 'Free',
    },
    {
        key: 2,
        totalStoreOrder: '51 - 100',
        lowerBound: 51,
        upperBound: 100,
        features: 'Full Features',
        monthlyPrice: 7.99,
    },
    {
        key: 3,
        totalStoreOrder: '101 - 200',
        lowerBound: 101,
        upperBound: 200,
        features: 'Full Features',
        monthlyPrice: 14.99,
    },
    {
        key: 4,
        totalStoreOrder: '201 - 350',
        lowerBound: 201,
        upperBound: 350,
        features: 'Full Features',
        monthlyPrice: 19.99,
    },
    {
        key: 5,
        totalStoreOrder: '351 - 500',
        lowerBound: 351,
        upperBound: 500,
        features: 'Full Features',
        monthlyPrice: 29.99,
    },
    {
        key: 6,
        totalStoreOrder: '501 - 1000',
        lowerBound: 501,
        upperBound: 1000,
        features: 'Full Features',
        monthlyPrice: 49.99,
    },
    {
        key: 7,
        totalStoreOrder: '1001 - 1700',
        lowerBound: 1001,
        upperBound: 1700,
        features: 'Full Features',
        monthlyPrice: 79.99,
    },
    {
        key: 8,
        totalStoreOrder: '1701 - 2600',
        lowerBound: 1701,
        upperBound: 2600,
        features: 'Full Features',
        monthlyPrice: 99.99,
    },
    {
        key: 9,
        totalStoreOrder: '2601 - 3600',
        lowerBound: 2601,
        upperBound: 3600,
        features: 'Full Features',
        monthlyPrice: 129.99,
    },
    {
        key: 10,
        totalStoreOrder: '3601 - 5000',
        lowerBound: 3601,
        upperBound: 5000,
        features: 'Full Features',
        monthlyPrice: 159.99,
    },
    {
        key: 11,
        totalStoreOrder: '5001 - 10000',
        lowerBound: 5001,
        upperBound: 10000,
        features: 'Full Features',
        monthlyPrice: 189.99,
    },
    {
        key: 12,
        totalStoreOrder: '10001 - 30000',
        lowerBound: 10001,
        upperBound: 30000,
        features: 'Full Features',
        monthlyPrice: 199.99,
    },
    {
        key: 13,
        totalStoreOrder: '30001 - 60000',
        lowerBound: 30001,
        upperBound: 60000,
        features: 'Full Features',
        monthlyPrice: 229.99,
    },
    {
        key: 14,
        totalStoreOrder: '60001 - 100000',
        lowerBound: 60001,
        upperBound: 100000,
        features: 'Full Features',
        monthlyPrice: 249.99,
    },
    {
        key: 15,
        totalStoreOrder: 'above 100000',
        lowerBound: 100001,
        upperBound: null,
        features: 'Full Features',
        monthlyPrice: 'Contact Us',
    },
];

// congratz modal steps
export const FIRST_STEP = 'test-app';
export const SECOND_STEP = 'all-works-perfect';
export const THIRD_STEP = 'review';

// faq initial data
export const faqData = {
    title: '',
    description: '',
    status: true,
    category: 'howItWorks',
    tags: ['ufe'],
    priority: null,
};

export const colors = [
    'magenta',
    'red',
    'volcano',
    'orange',
    'gold',
    'lime',
    'green',
    'cyan',
    'blue',
    'geekblue',
    'purple',
];

export const faqCategories = [
    { value: 'howItWorks', label: 'How to do it?' },
    { value: 'notWorking', label: 'Not working' },
    { value: 'issues', label: 'Issues' },
    { value: 'newFeatures', label: 'New Features' },
    { value: 'beginnerTutorials', label: 'Beginner Tutorials' },
];
