import { UFE_LOADING, UFE_LOADING_SUCCESS } from '../actions';

export const loader = (state = {}, action) => {
    switch (action.type) {
        case UFE_LOADING:
            return {
                ...state,
                loading: true,
            };
        case UFE_LOADING_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};
