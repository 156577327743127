/* eslint-disable no-shadow */
/* eslint-disable import/no-cycle */
import { deepClone } from '../../../common/helper/commonMethods';
import defaultSettings from '~helixo/common/defaultSettings';

export const offer = {
    products: undefined,
    minQty: 1,
    discount: {
        dValue: 10,
        dType: '%Off',
    },
    mostPopular: false,
    upgrade: false,
};
// fonts
export const upsellCount = {
    allPages: 0,
    productPage: 0,
    cartPage: 0,
    thankyouPage: 0,
    homePage: 0,
    collectionsPage: 0,
};
// value field only for default fonts
// if value not exists then value is name
export const fonts = [
    {
        fontFamily: '',
        name: 'Inherit From Store',
    },
    {
        fontFamily: '',
        name: 'Customer Device Font',
    },
    {
        fontFamily: "'Abril Fatface', cursive",
        name: 'Abril Fatface',
    },
    {
        fontFamily: "'Alegreya', serif",
        name: 'Alegreya',
    },
    {
        fontFamily: "'Archivo', sans-serif",
        name: 'Archivo',
    },
    {
        fontFamily: "'Arvo', serif",
        name: 'Arvo',
    },
    {
        fontFamily: "'B612', sans-serif",
        name: 'B612',
    },
    {
        fontFamily: "'BioRhyme', serif",
        name: 'BioRhyme',
    },
    {
        fontFamily: "'Cairo', sans-serif",
        name: 'Cairo',
    },
    {
        fontFamily: "'Cardo', serif",
        name: 'Cardo',
    },
    {
        fontFamily: "'Concert One', cursive",
        name: 'Concert One',
    },
    {
        fontFamily: "'Cormorant', serif",
        name: 'Cormorant',
    },
    {
        fontFamily: "'Crimson Text', serif",
        name: 'Crimson Text',
    },
    {
        fontFamily: "'Fjalla One', sans-serif",
        name: 'Fjalla One',
    },
    {
        fontFamily: "'Frank Ruhl Libre', serif",
        name: 'Frank Ruhl Libre',
    },
    {
        fontFamily: "'IBM Plex Sans', sans-serif",
        name: 'IBM Plex Sans',
    },
    {
        fontFamily: "'Karla', sans-serif",
        name: 'Karla',
    },
    {
        fontFamily: "'Lato', sans-serif",
        name: 'Lato',
    },
    {
        fontFamily: "'Lora', serif",
        name: 'Lora',
    },
    {
        fontFamily: "'Montserrat', sans-serif",
        name: 'Montserrat',
    },
    {
        fontFamily: "'Muli', sans-serif",
        name: 'Muli',
    },
    {
        fontFamily: "'Old Standard TT', serif",
        name: 'Old Standard TT',
    },
    {
        fontFamily: "'Oswald', sans-serif",
        name: 'Oswald',
    },
    {
        fontFamily: "'Playfair Display', serif",
        name: 'Playfair Display',
    },
    {
        fontFamily: "'PT Serif', serif",
        name: 'PT Serif',
    },
    {
        fontFamily: "'Rakkas', cursive",
        name: 'Rakkas',
    },
    {
        fontFamily: "'Roboto', sans-serif",
        name: 'Roboto',
    },
    {
        fontFamily: "'Rubik', sans-serif",
        name: 'Rubik',
    },
    {
        fontFamily: "'Rubik', sans-serif",
        name: 'Source Sans Pro',
    },
    {
        fontFamily: "'Spectral', serif",
        name: 'Spectral',
    },
    {
        fontFamily: "'Titillium Web', sans-serif",
        name: 'Titillium Web',
    },
    {
        fontFamily: "'Ubuntu', sans-serif",
        name: 'Ubuntu',
    },
    {
        fontFamily: "'Varela', sans-serif",
        name: 'Varela',
    },
    {
        fontFamily: "'Vollkorn', serif",
        name: 'Vollkorn',
    },
    {
        fontFamily: "'Work Sans', sans-serif",
        name: 'Work Sans',
    },
    {
        fontFamily: "'Yatra One', cursive",
        name: 'Yatra One',
    },
];
// template and theme
// plan defines who can access the template
export const templates = [
    {
        plan: ['free', 'unlimited', 'payPerUse', 'startup_plan'],
        template: 'ufeModern',
        themes: [
            {
                plan: ['free', 'unlimited', 'payPerUse', 'startup_plan'],
                restrictedTypes: [],
                theme: 'default',
            },
            {
                plan: ['free', 'unlimited', 'payPerUse', 'startup_plan'],
                restrictedTypes: [],
                theme: 'ufeClassic',
            },
            {
                plan: ['unlimited', 'payPerUse', 'startup_plan'],
                restrictedTypes: ['inpage'],
                theme: 'xmas',
            },
            {
                plan: ['unlimited', 'payPerUse', 'startup_plan'],
                restrictedTypes: ['inpage'],
                theme: 'xmas-snow-outside',
            },
            {
                plan: ['unlimited', 'payPerUse', 'startup_plan'],
                restrictedTypes: ['inpage'],
                theme: 'xmas-snow-inside',
            },
            // {
            //     plan: ['free', 'pro'],
            //     theme: 'amazon',
            // },
            // {
            //     plan: ['pro'],
            //     theme: 'aliexpress',
            // },
        ],
    },
    {
        plan: [],
        template: 'berlin',
        themes: [
            {
                plan: [],
                restrictedTypes: [],
                theme: 'default',
            },
            // {
            //     plan: ['free', 'pro'],
            //     theme: 'amazon',
            // },
            // {
            //     plan: ['pro'],
            //     theme: 'aliexpress',
            // },
        ],
    },
    {
        plan: [],
        template: 'tokyo',
        themes: [
            {
                plan: ['free', 'pro'],
                restrictedTypes: [],
                theme: 'default',
            },
            // {
            //     plan: ['free', 'pro'],
            //     theme: 'amazon',
            // },
            // {
            //     plan: ['pro'],
            //     theme: 'aliexpress',
            // },
        ],
    },
];

// add default from client side
const normaliseSettings = (defaultSettings) => {
    const newSettings = {
        ...defaultSettings,
        general: {
            ...defaultSettings.general,
            rejectButtonBehaviour: 'continueShopping',
        },
    };

    return newSettings;
};

/**
 * @param defaultSettings contains the default settings of the widget
 * which is a common module imported from ~helixo/common/defaultSettings.js
 * DON'T MODIFY PROPERTY HERE, USE THE COMMON FILE
 */
export const settings = normaliseSettings(defaultSettings);

/**
 * product custom properties defaults
 */
export const customProperties = {
    title: '',
    descriptionType: 'none',
    description: '',
    showShortDescription: true,
};
const { widgetSpecific } = defaultSettings;

export const upsell = {
    active: false,
    uuid: 1234,
    page: null,
    sortOrder: null,
    behaviour: null,
    upsellSettings: {
        widgetSpecific: {
            ...widgetSpecific,
            // widgetSpecific: {
            //     bundle: {
            //         bundleWithTriggerProduct: true,
            //     },
            //     volumeDiscount: {
            //         allowVariantSelection: true,
            //     },
            // },
        },
    },
    upsellSettingsDifference: {},
    offers: [deepClone(offer)],
    downsell: [],
    upsellType: 'normal',
};

export const tracking = {
    fbPixelStatus: false,
    fbPixelId: '',
    googleAnalyticsStatus: false,
    googleAnalyticsId: '',
};
// const performance based settings
export const performance = {
    improveScriptLoadingSpeed: false,
};
// common settings
export const commonSettings = {
    ...settings,
    tracking: {
        ...settings.tracking,
        ...tracking,
    },
    performance,
};

// default value of filter
export const filters = [
    {
        active: false,
        name: 'location',
        subCat: 'country',
        operator: 'eq',
        value: [],
    },
    {
        active: false,
        name: 'products',
        subCat: 'products',
        operator: 'eq',
        value: [],
    },
    {
        active: false,
        name: 'cart',
        subCat: 'total',
        operator: 'eq',
        value: 50,
    },
    {
        active: false,
        name: 'offer',
        subCat: 'orders',
        operator: 'eq',
        value: 50,
    },
];

/**
 * `Returns` default values for new funnel data
 */
export const newFunnelData = () => ({
    funnelName: null,
    triggerSource: 'specificProducts',
    triggerPage: 'specificPages',
    trigger: {
        products: undefined,
    },
    active: true,
    funnelSettings: {},
    allPages: {
        splitTest: [],
    },
    productPage: {
        splitTest: [],
    },
    cartPage: {
        splitTest: [],
    },
    thankyouPage: {
        splitTest: [],
    },
    collectionPage: {
        splitTest: [],
    },
    homePage: {
        splitTest: [],
    },
    filters: deepClone(filters),
    upsellCount: deepClone(upsellCount),
});

// wizard
export const wizardData = {
    widgetHook: 'prePurchase',
    upsellType: 'normal',
    trigger: {
        products: undefined,
    },
    offers: [deepClone(offer)],
};
// wizard offer types
export const NORMAL = 'normal';
export const PRODUCT_WITHOUT_DISCOUNT = 'productWithoutDiscount';
export const FREE_PRODUCT = 'freeProduct';
export const BUNDLE = 'bundle';
export const VOLUME_DISCOUNT = 'volume_discount';
export const FBT = 'fbt';

// wizard widget hooks

export const PRE_PURCHASE = 'prePurchase';
export const POST_PURCHASE = 'postPurchase';

// page specific settings
export const pageSpecificSettings = {
    productPage: {},
    cartPage: {
        general: {
            skipCart: true,
        },
    },
    thankyouPage: {
        general: {
            skipCart: true,
        },
    },
    homePage: {},
    collectionsPage: {},
};

// for apply to all
// titles
export const designTitles = [
    {
        label: 'settings.designTitles.general',
        properties: ['design.template', 'design.theme', 'design.font', 'design.imageView'],
    },
    {
        label: 'settings.designTitles.main',
        properties: ['design.colors.topBarColor', 'design.colors.secondary'],
    },
    {
        label: 'settings.designTitles.buttons',
        properties: [
            'design.colors.acceptBtnColor',
            'design.colors.rejectBtnColor',
            'design.colors.checkoutBtnColor',
            'design.colors.deleteBtn',
            'design.colors.floatingBtn',
        ],
    },
    {
        label: 'settings.designTitles.text',
        properties: ['design.colors.salePriceColor', 'design.colors.comparePriceColor', 'design.colors.timer'],
    },
    {
        label: 'settings.designTitles.badges',
        properties: ['design.colors.mostPopularColor', 'design.colors.bundleBadge', 'design.colors.saveBadge'],
    },
    {
        label: 'settings.designTitles.cards',
        properties: [
            'design.colors.mostPopularCard',
            'design.colors.offerDisplayCard',
            'design.colors.freeShippingColor',
            'design.colors.popupBgColor',
            'design.colors.addressFormBgColor',
        ],
    },
    {
        label: 'settings.designTitles.additional',
        properties: ['design.additionalCSS'],
    },
];

export const funnelSettingsTitle = [
    {
        label: 'settings.funnelSettingsTitles.behaviour',
        properties: [
            'general.upsellStyle',
            'floatingPosition',
            'general.immediateTrigger',
            'general.popupDelay',
            'general.addProductLink',
            'general.fbtAllChecked',
            'general.hideCheckoutBtn',
            'general.addTriggerAlongWithUpsell',
            'general.rejectButtonBehaviour',
        ],
    },
    {
        label: 'settings.funnelSettingsTitles.general',
        properties: [
            'general.skipCart',
            'general.integrateCustomerAddress',
            'general.rejectActionOnClose',
            'general.allowQuantitySelector',
            'general.hideAtcForSingleOffer',
            'general.hideMiniCart',
            'general.comparePriceBehaviour',
        ],
    },
    {
        label: 'settings.funnelSettingsTitles.offerBased',
        properties: [
            'general.timer.timer',
            'general.freeShipping',
            'general.timer.expiryAction',
            'general.timer.status',
        ],
    },
    {
        label: 'settings.funnelSettingsTitles.scheduling',
        properties: ['general.validity'],
    },
    {
        label: 'settings.funnelSettingsTitles.translationTitles',
        properties: ['translations.upsellTitle', 'translations.cartText', 'translations.shippingAddressText'],
    },
    {
        label: 'settings.funnelSettingsTitles.translationCommonTexts',
        properties: [
            'translations.totalText',
            'translations.generatingOfferText',
            'translations.redirectToCartText',
            'translations.timerText',
            'translations.freeShipping',
            'translations.free',
        ],
    },
    {
        label: 'settings.funnelSettingsTitles.translationsVolumeDiscountTexts',
        properties: ['translations.quantitiesText', 'translations.eachItemText'],
    },
    {
        label: 'settings.funnelSettingsTitles.translationsPopupUpsellTexts',
        properties: ['translations.nextOfferBtn', 'translations.prevOfferBtn'],
    },
    {
        label: 'settings.funnelSettingsTitles.translationsBadges',
        properties: ['translations.bundleText', 'translations.saveText', 'translations.mostPopularText'],
    },
    {
        label: 'settings.funnelSettingsTitles.translationsButtons',
        properties: [
            'translations.acceptBtn',
            'translations.rejectBtn',
            'translations.deleteBtn',
            'translations.closeBtn',
            'translations.upgradeProduct',
        ],
    },
    {
        label: 'settings.funnelSettingsTitles.translationsCheckoutButtons',
        properties: ['translations.checkoutBtnText', 'translations.nextBtnText', 'translations.goToCartBtn'],
    },
    {
        label: 'settings.funnelSettingsTitles.translationsWidgetSpeceficButtons',
        properties: ['translations.floatingBtn'],
    },
    {
        label: 'settings.funnelSettingsTitles.translationsVolumeDiscountButtons',
        properties: ['translations.addQuantityBtn', 'translations.addVariantBtn'],
    },
    {
        label: 'settings.funnelSettingsTitles.inpageUpsellPosition',
        properties: [
            'position.productPage',
            'position.cartPage',
            'position.thankyouPage',
            'position.homePage',
            'position.collectionsPage',
            'position.allPages',
        ],
    },
    {
        label: 'settings.funnelSettingsTitles.popupUpsellPosition',
        properties: [
            'trigger.productPage',
            'trigger.cartPage',
            'trigger.thankyouPage',
            'trigger.homePage',
            'trigger.collectionsPage',
            'trigger.allPages',
        ],
    },
    {
        label: 'settings.funnelSettingsTitles.inpagePositionType',
        properties: ['inpagePositionType'],
    },
];

// all countries
export const countries = [
    {
        name: 'Afghanistan',
        code: 'AF',
    },
    {
        name: 'Åland Islands',
        code: 'AX',
    },
    {
        name: 'Albania',
        code: 'AL',
    },
    {
        name: 'Algeria',
        code: 'DZ',
    },
    {
        name: 'American Samoa',
        code: 'AS',
    },
    {
        name: 'AndorrA',
        code: 'AD',
    },
    {
        name: 'Angola',
        code: 'AO',
    },
    {
        name: 'Anguilla',
        code: 'AI',
    },
    {
        name: 'Antarctica',
        code: 'AQ',
    },
    {
        name: 'Antigua and Barbuda',
        code: 'AG',
    },
    {
        name: 'Argentina',
        code: 'AR',
    },
    {
        name: 'Armenia',
        code: 'AM',
    },
    {
        name: 'Aruba',
        code: 'AW',
    },
    {
        name: 'Australia',
        code: 'AU',
    },
    {
        name: 'Austria',
        code: 'AT',
    },
    {
        name: 'Azerbaijan',
        code: 'AZ',
    },
    {
        name: 'Bahamas',
        code: 'BS',
    },
    {
        name: 'Bahrain',
        code: 'BH',
    },
    {
        name: 'Bangladesh',
        code: 'BD',
    },
    {
        name: 'Barbados',
        code: 'BB',
    },
    {
        name: 'Belarus',
        code: 'BY',
    },
    {
        name: 'Belgium',
        code: 'BE',
    },
    {
        name: 'Belize',
        code: 'BZ',
    },
    {
        name: 'Benin',
        code: 'BJ',
    },
    {
        name: 'Bermuda',
        code: 'BM',
    },
    {
        name: 'Bhutan',
        code: 'BT',
    },
    {
        name: 'Bolivia',
        code: 'BO',
    },
    {
        name: 'Bosnia and Herzegovina',
        code: 'BA',
    },
    {
        name: 'Botswana',
        code: 'BW',
    },
    {
        name: 'Bouvet Island',
        code: 'BV',
    },
    {
        name: 'Brazil',
        code: 'BR',
    },
    {
        name: 'British Indian Ocean Territory',
        code: 'IO',
    },
    {
        name: 'Brunei Darussalam',
        code: 'BN',
    },
    {
        name: 'Bulgaria',
        code: 'BG',
    },
    {
        name: 'Burkina Faso',
        code: 'BF',
    },
    {
        name: 'Burundi',
        code: 'BI',
    },
    {
        name: 'Cambodia',
        code: 'KH',
    },
    {
        name: 'Cameroon',
        code: 'CM',
    },
    {
        name: 'Canada',
        code: 'CA',
    },
    {
        name: 'Cape Verde',
        code: 'CV',
    },
    {
        name: 'Cayman Islands',
        code: 'KY',
    },
    {
        name: 'Central African Republic',
        code: 'CF',
    },
    {
        name: 'Chad',
        code: 'TD',
    },
    {
        name: 'Chile',
        code: 'CL',
    },
    {
        name: 'China',
        code: 'CN',
    },
    {
        name: 'Christmas Island',
        code: 'CX',
    },
    {
        name: 'Cocos (Keeling) Islands',
        code: 'CC',
    },
    {
        name: 'Colombia',
        code: 'CO',
    },
    {
        name: 'Comoros',
        code: 'KM',
    },
    {
        name: 'Congo',
        code: 'CG',
    },
    {
        name: 'Congo, The Democratic Republic of the',
        code: 'CD',
    },
    {
        name: 'Cook Islands',
        code: 'CK',
    },
    {
        name: 'Costa Rica',
        code: 'CR',
    },
    {
        name: 'Cote D"Ivoire',
        code: 'CI',
    },
    {
        name: 'Croatia',
        code: 'HR',
    },
    {
        name: 'Cuba',
        code: 'CU',
    },
    {
        name: 'Cyprus',
        code: 'CY',
    },
    {
        name: 'Czech Republic',
        code: 'CZ',
    },
    {
        name: 'Denmark',
        code: 'DK',
    },
    {
        name: 'Djibouti',
        code: 'DJ',
    },
    {
        name: 'Dominica',
        code: 'DM',
    },
    {
        name: 'Dominican Republic',
        code: 'DO',
    },
    {
        name: 'Ecuador',
        code: 'EC',
    },
    {
        name: 'Egypt',
        code: 'EG',
    },
    {
        name: 'El Salvador',
        code: 'SV',
    },
    {
        name: 'Equatorial Guinea',
        code: 'GQ',
    },
    {
        name: 'Eritrea',
        code: 'ER',
    },
    {
        name: 'Estonia',
        code: 'EE',
    },
    {
        name: 'Ethiopia',
        code: 'ET',
    },
    {
        name: 'Falkland Islands (Malvinas)',
        code: 'FK',
    },
    {
        name: 'Faroe Islands',
        code: 'FO',
    },
    {
        name: 'Fiji',
        code: 'FJ',
    },
    {
        name: 'Finland',
        code: 'FI',
    },
    {
        name: 'France',
        code: 'FR',
    },
    {
        name: 'French Guiana',
        code: 'GF',
    },
    {
        name: 'French Polynesia',
        code: 'PF',
    },
    {
        name: 'French Southern Territories',
        code: 'TF',
    },
    {
        name: 'Gabon',
        code: 'GA',
    },
    {
        name: 'Gambia',
        code: 'GM',
    },
    {
        name: 'Georgia',
        code: 'GE',
    },
    {
        name: 'Germany',
        code: 'DE',
    },
    {
        name: 'Ghana',
        code: 'GH',
    },
    {
        name: 'Gibraltar',
        code: 'GI',
    },
    {
        name: 'Greece',
        code: 'GR',
    },
    {
        name: 'Greenland',
        code: 'GL',
    },
    {
        name: 'Grenada',
        code: 'GD',
    },
    {
        name: 'Guadeloupe',
        code: 'GP',
    },
    {
        name: 'Guam',
        code: 'GU',
    },
    {
        name: 'Guatemala',
        code: 'GT',
    },
    {
        name: 'Guernsey',
        code: 'GG',
    },
    {
        name: 'Guinea',
        code: 'GN',
    },
    {
        name: 'Guinea-Bissau',
        code: 'GW',
    },
    {
        name: 'Guyana',
        code: 'GY',
    },
    {
        name: 'Haiti',
        code: 'HT',
    },
    {
        name: 'Heard Island and Mcdonald Islands',
        code: 'HM',
    },
    {
        name: 'Holy See (Vatican City State)',
        code: 'VA',
    },
    {
        name: 'Honduras',
        code: 'HN',
    },
    {
        name: 'Hong Kong',
        code: 'HK',
    },
    {
        name: 'Hungary',
        code: 'HU',
    },
    {
        name: 'Iceland',
        code: 'IS',
    },
    {
        name: 'India',
        code: 'IN',
    },
    {
        name: 'Indonesia',
        code: 'ID',
    },
    {
        name: 'Iran, Islamic Republic Of',
        code: 'IR',
    },
    {
        name: 'Iraq',
        code: 'IQ',
    },
    {
        name: 'Ireland',
        code: 'IE',
    },
    {
        name: 'Isle of Man',
        code: 'IM',
    },
    {
        name: 'Israel',
        code: 'IL',
    },
    {
        name: 'Italy',
        code: 'IT',
    },
    {
        name: 'Jamaica',
        code: 'JM',
    },
    {
        name: 'Japan',
        code: 'JP',
    },
    {
        name: 'Jersey',
        code: 'JE',
    },
    {
        name: 'Jordan',
        code: 'JO',
    },
    {
        name: 'Kazakhstan',
        code: 'KZ',
    },
    {
        name: 'Kenya',
        code: 'KE',
    },
    {
        name: 'Kiribati',
        code: 'KI',
    },
    {
        name: 'Korea, Democratic People"S Republic of',
        code: 'KP',
    },
    {
        name: 'Korea, Republic of',
        code: 'KR',
    },
    {
        name: 'Kuwait',
        code: 'KW',
    },
    {
        name: 'Kyrgyzstan',
        code: 'KG',
    },
    {
        name: 'Lao People"S Democratic Republic',
        code: 'LA',
    },
    {
        name: 'Latvia',
        code: 'LV',
    },
    {
        name: 'Lebanon',
        code: 'LB',
    },
    {
        name: 'Lesotho',
        code: 'LS',
    },
    {
        name: 'Liberia',
        code: 'LR',
    },
    {
        name: 'Libyan Arab Jamahiriya',
        code: 'LY',
    },
    {
        name: 'Liechtenstein',
        code: 'LI',
    },
    {
        name: 'Lithuania',
        code: 'LT',
    },
    {
        name: 'Luxembourg',
        code: 'LU',
    },
    {
        name: 'Macao',
        code: 'MO',
    },
    {
        name: 'Macedonia, The Former Yugoslav Republic of',
        code: 'MK',
    },
    {
        name: 'Madagascar',
        code: 'MG',
    },
    {
        name: 'Malawi',
        code: 'MW',
    },
    {
        name: 'Malaysia',
        code: 'MY',
    },
    {
        name: 'Maldives',
        code: 'MV',
    },
    {
        name: 'Mali',
        code: 'ML',
    },
    {
        name: 'Malta',
        code: 'MT',
    },
    {
        name: 'Marshall Islands',
        code: 'MH',
    },
    {
        name: 'Martinique',
        code: 'MQ',
    },
    {
        name: 'Mauritania',
        code: 'MR',
    },
    {
        name: 'Mauritius',
        code: 'MU',
    },
    {
        name: 'Mayotte',
        code: 'YT',
    },
    {
        name: 'Mexico',
        code: 'MX',
    },
    {
        name: 'Micronesia, Federated States of',
        code: 'FM',
    },
    {
        name: 'Moldova, Republic of',
        code: 'MD',
    },
    {
        name: 'Monaco',
        code: 'MC',
    },
    {
        name: 'Mongolia',
        code: 'MN',
    },
    {
        name: 'Montserrat',
        code: 'MS',
    },
    {
        name: 'Morocco',
        code: 'MA',
    },
    {
        name: 'Mozambique',
        code: 'MZ',
    },
    {
        name: 'Myanmar',
        code: 'MM',
    },
    {
        name: 'Namibia',
        code: 'NA',
    },
    {
        name: 'Nauru',
        code: 'NR',
    },
    {
        name: 'Nepal',
        code: 'NP',
    },
    {
        name: 'Netherlands',
        code: 'NL',
    },
    {
        name: 'Netherlands Antilles',
        code: 'AN',
    },
    {
        name: 'New Caledonia',
        code: 'NC',
    },
    {
        name: 'New Zealand',
        code: 'NZ',
    },
    {
        name: 'Nicaragua',
        code: 'NI',
    },
    {
        name: 'Niger',
        code: 'NE',
    },
    {
        name: 'Nigeria',
        code: 'NG',
    },
    {
        name: 'Niue',
        code: 'NU',
    },
    {
        name: 'Norfolk Island',
        code: 'NF',
    },
    {
        name: 'Northern Mariana Islands',
        code: 'MP',
    },
    {
        name: 'Norway',
        code: 'NO',
    },
    {
        name: 'Oman',
        code: 'OM',
    },
    {
        name: 'Pakistan',
        code: 'PK',
    },
    {
        name: 'Palau',
        code: 'PW',
    },
    {
        name: 'Palestinian Territory, Occupied',
        code: 'PS',
    },
    {
        name: 'Panama',
        code: 'PA',
    },
    {
        name: 'Papua New Guinea',
        code: 'PG',
    },
    {
        name: 'Paraguay',
        code: 'PY',
    },
    {
        name: 'Peru',
        code: 'PE',
    },
    {
        name: 'Philippines',
        code: 'PH',
    },
    {
        name: 'Pitcairn',
        code: 'PN',
    },
    {
        name: 'Poland',
        code: 'PL',
    },
    {
        name: 'Portugal',
        code: 'PT',
    },
    {
        name: 'Puerto Rico',
        code: 'PR',
    },
    {
        name: 'Qatar',
        code: 'QA',
    },
    {
        name: 'Reunion',
        code: 'RE',
    },
    {
        name: 'Romania',
        code: 'RO',
    },
    {
        name: 'Russian Federation',
        code: 'RU',
    },
    {
        name: 'RWANDA',
        code: 'RW',
    },
    {
        name: 'Saint Helena',
        code: 'SH',
    },
    {
        name: 'Saint Kitts and Nevis',
        code: 'KN',
    },
    {
        name: 'Saint Lucia',
        code: 'LC',
    },
    {
        name: 'Saint Pierre and Miquelon',
        code: 'PM',
    },
    {
        name: 'Saint Vincent and the Grenadines',
        code: 'VC',
    },
    {
        name: 'Samoa',
        code: 'WS',
    },
    {
        name: 'San Marino',
        code: 'SM',
    },
    {
        name: 'Sao Tome and Principe',
        code: 'ST',
    },
    {
        name: 'Saudi Arabia',
        code: 'SA',
    },
    {
        name: 'Senegal',
        code: 'SN',
    },
    {
        name: 'Serbia and Montenegro',
        code: 'CS',
    },
    {
        name: 'Seychelles',
        code: 'SC',
    },
    {
        name: 'Sierra Leone',
        code: 'SL',
    },
    {
        name: 'Singapore',
        code: 'SG',
    },
    {
        name: 'Slovakia',
        code: 'SK',
    },
    {
        name: 'Slovenia',
        code: 'SI',
    },
    {
        name: 'Solomon Islands',
        code: 'SB',
    },
    {
        name: 'Somalia',
        code: 'SO',
    },
    {
        name: 'South Africa',
        code: 'ZA',
    },
    {
        name: 'South Georgia and the South Sandwich Islands',
        code: 'GS',
    },
    {
        name: 'Spain',
        code: 'ES',
    },
    {
        name: 'Sri Lanka',
        code: 'LK',
    },
    {
        name: 'Sudan',
        code: 'SD',
    },
    {
        name: 'Suriname',
        code: 'SR',
    },
    {
        name: 'Svalbard and Jan Mayen',
        code: 'SJ',
    },
    {
        name: 'Swaziland',
        code: 'SZ',
    },
    {
        name: 'Sweden',
        code: 'SE',
    },
    {
        name: 'Switzerland',
        code: 'CH',
    },
    {
        name: 'Syrian Arab Republic',
        code: 'SY',
    },
    {
        name: 'Taiwan, Province of China',
        code: 'TW',
    },
    {
        name: 'Tajikistan',
        code: 'TJ',
    },
    {
        name: 'Tanzania, United Republic of',
        code: 'TZ',
    },
    {
        name: 'Thailand',
        code: 'TH',
    },
    {
        name: 'Timor-Leste',
        code: 'TL',
    },
    {
        name: 'Togo',
        code: 'TG',
    },
    {
        name: 'Tokelau',
        code: 'TK',
    },
    {
        name: 'Tonga',
        code: 'TO',
    },
    {
        name: 'Trinidad and Tobago',
        code: 'TT',
    },
    {
        name: 'Tunisia',
        code: 'TN',
    },
    {
        name: 'Turkey',
        code: 'TR',
    },
    {
        name: 'Turkmenistan',
        code: 'TM',
    },
    {
        name: 'Turks and Caicos Islands',
        code: 'TC',
    },
    {
        name: 'Tuvalu',
        code: 'TV',
    },
    {
        name: 'Uganda',
        code: 'UG',
    },
    {
        name: 'Ukraine',
        code: 'UA',
    },
    {
        name: 'United Arab Emirates',
        code: 'AE',
    },
    {
        name: 'United Kingdom',
        code: 'GB',
    },
    {
        name: 'United States',
        code: 'US',
    },
    {
        name: 'United States Minor Outlying Islands',
        code: 'UM',
    },
    {
        name: 'Uruguay',
        code: 'UY',
    },
    {
        name: 'Uzbekistan',
        code: 'UZ',
    },
    {
        name: 'Vanuatu',
        code: 'VU',
    },
    {
        name: 'Venezuela',
        code: 'VE',
    },
    {
        name: 'Viet Nam',
        code: 'VN',
    },
    {
        name: 'Virgin Islands, British',
        code: 'VG',
    },
    {
        name: 'Virgin Islands, U.S.',
        code: 'VI',
    },
    {
        name: 'Wallis and Futuna',
        code: 'WF',
    },
    {
        name: 'Western Sahara',
        code: 'EH',
    },
    {
        name: 'Yemen',
        code: 'YE',
    },
    {
        name: 'Zambia',
        code: 'ZM',
    },
    {
        name: 'Zimbabwe',
        code: 'ZW',
    },
];
