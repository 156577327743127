/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import { omit, merge } from 'lodash';
import { deepClone, getIt } from '../../../common/helper/commonMethods';
import { globalState } from '../../../common/helper/globalState';
import { offer } from './dataProvider';
import { NORMAL_OFFER, BUNDLE_OFFER } from '../../../common/helper/constants';
// eslint-disable-next-line import/no-cycle
import { pages } from '../../../state/actions/funnel';
import defaultSettings from '~helixo/common/defaultSettings';

/**
 * `Link` https://stackoverflow.com/questions/5484673/javascript-how-to-dynamically-create-nested-objects-using-object-names-given-by
 * dynamically creating nested objects by using array of string
 * @param {*} obj object from array of strings, will be { a : { b: value }}
 * @param {*} keyPath Array of string values, will be ['a', 'b']
 * @param {*} value value of the object
 */
export const getObjectFromNamePath = (keyPath, value) => {
    let obj = {};
    const lastKeyIndex = keyPath.length - 1;
    for (let i = 0; i < lastKeyIndex; ++i) {
        const key = keyPath[i];
        if (!(key in obj)) {
            // obj[key] = isNaN(key) ? {}: []
            obj[key] = key === 'upsells' || key === 'splitTest' ? [] : {};
        }
        obj = obj[key];
    }
    obj[keyPath[lastKeyIndex]] = value;
    return obj;
};

export const createUUID = () =>
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    `_${Math.random().toString(36).substr(2, 9)}`;

/**
 * `Returns` an object that represent an offer, offer details will change according to upsell type
 * @param {*} form antd form instance
 * @param {*} path name path, string array for object traversal
 * @param {*} upsellTypeChanged boolean value, indicating upsell type changed or not
 */
export const findOfferData = (form, path, upsellTypeChanged = false) => {
    const offerData = deepClone(offer);
    const { getFieldValue } = form;
    // find the upsell type
    const upsellType = getFieldValue([...path, 'upsellType']);
    if (upsellType === 'volume_discount') {
        /**
         * increment minQty by 1.
         * if no offer is present, then start minQty from 2
         */
        let offers = getFieldValue([...path, 'offers']) || [];
        // remove upsells having null value
        offers = offers.filter((a) => a);
        const previousOffer = offers.length ? offers[offers.length - 1] : undefined;
        /**
         * if upsells is empty then minQty will be 2
         * else, add the minQty of the last upsell with 1
         * if the upsell type is changed then no need to consider previousOffer min qty value
         */
        offerData.minQty = !previousOffer || upsellTypeChanged ? 2 : +previousOffer.minQty + 1;

        offerData.discount.dValue =
            !previousOffer || upsellTypeChanged
                ? 10
                : previousOffer.discount.dValue + 10 > 50
                ? previousOffer.discount.dValue
                : +previousOffer.discount.dValue + 10;
    }
    return offerData;
};

export const getUpsellName = ({ namePath, behaviour, hasSplitTest }) => {
    const { translate: t } = globalState;
    let title = '';

    switch (behaviour) {
        case 'upsells': {
            const [index] = namePath;
            title = hasSplitTest ? `Split Test #A` : `Upsell #${index + 1}`;
            break;
        }
        case 'downsell': {
            title = `Downsell`;
            break;
        }
        case 'splitTest': {
            const [index] = namePath;
            title = `Split test #${String.fromCharCode(66 + index)}`;
            break;
        }
        default:
            break;
    }
    return title;
};

export const splitTestExist = (page, formValue) => {
    let splitTestData = getIt(formValue, `upsellFunnels.${page}.splitTest`, []);
    splitTestData = splitTestData.filter((splitTest = {}) => (splitTest.upsells || []).length > 0);
    return splitTestData.length > 0;
};
/**
 * Update object property using string path
 * @param {*} object Target object to be updated
 * @param {*} newValue value to be updated
 * @param {*} path string path to the target field
 */
export const updateObject = (object, newValue, stack) => {
    while (stack.length > 1) {
        // eslint-disable-next-line no-param-reassign
        object = object[stack.shift()];
    }

    object[stack.shift()] = newValue;
};

/**
 * to check a propert exist or not in an object
 * @param {*} object
 * @param {*} property it is an array holding path
 */
export const propertyExist = (object, property) => {
    for (let i = 0; i < property.length; i++) {
        // eslint-disable-next-line no-prototype-builtins
        if (!object || !object.hasOwnProperty(property[i])) {
            return false;
        }
        // eslint-disable-next-line no-param-reassign
        object = object[property[i]];
    }
    return true;
};

export const setProperty = (schema, path, value) => {
    // a moving reference to internal objects within obj
    if (!schema) schema = {};
    const pList = path.split('.');
    const len = pList.length;
    for (let i = 0; i < len - 1; i++) {
        const elem = pList[i];
        if (!schema[elem]) schema[elem] = {};
        schema = schema[elem];
    }

    schema[pList[len - 1]] = value;
};

export const getProperty = (nestedObj, pathArr) =>
    pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined), nestedObj);

/**
 * delete all properties that contained in checked settings in upsellSettings difference
 * @param {*} formValues
 * @param {*} settings that wants to delete
 */
export const resetUpsellSettings = (formValues, checkedSettings) => {
    pages.forEach((page) => {
        // split test from corresponding page
        const { splitTest } = formValues[page] || {};
        (splitTest || []).forEach(({ upsells }, splitTestIndex) => {
            (upsells || []).forEach((upsell, upsellIndex) => {
                (checkedSettings || []).forEach((settings) => {
                    if (formValues[page].splitTest[splitTestIndex].upsells[upsellIndex].upsellSettingsDifference) {
                        formValues[page].splitTest[splitTestIndex].upsells[upsellIndex].upsellSettingsDifference = omit(
                            upsell.upsellSettingsDifference,
                            settings
                        );
                        // formValues[page].splitTest[splitTestIndex].upsells[
                        //     upsellIndex
                        // ].upsellSettings.general.upsellStyle = 'popup';
                        const settingsArray = settings.split('.');
                        setProperty(
                            formValues[page].splitTest[splitTestIndex].upsells[upsellIndex].upsellSettings,
                            settings,
                            getProperty(formValues.funnelSettings, [...settingsArray])
                        );
                        // immediate trigger is always true for thank you page when upsell style is popup
                        if (
                            page === 'thankyouPage' &&
                            settings === 'general.upsellStyle' &&
                            getProperty(formValues.funnelSettings, [...settingsArray]) === 'popup'
                        ) {
                            setProperty(
                                formValues[page].splitTest[splitTestIndex].upsells[upsellIndex]
                                    .upsellSettingsDifference,
                                'general.immediateTrigger',
                                true
                            );
                            setProperty(
                                formValues[page].splitTest[splitTestIndex].upsells[upsellIndex].upsellSettings,
                                'general.immediateTrigger',
                                true
                            );
                        }
                    }
                });
                (upsell.downsell || []).forEach((downsell, downsellIndex) => {
                    (checkedSettings || []).forEach((settings) => {
                        if (
                            formValues[page].splitTest[splitTestIndex].upsells[upsellIndex].downsell[downsellIndex]
                                .upsellSettingsDifference
                        ) {
                            formValues[page].splitTest[splitTestIndex].upsells[upsellIndex].downsell[
                                downsellIndex
                            ].upsellSettingsDifference = omit(downsell.upsellSettingsDifference, settings);
                            const settingsArray = settings.split('.');
                            setProperty(
                                formValues[page].splitTest[splitTestIndex].upsells[upsellIndex].downsell[downsellIndex]
                                    .upsellSettings,
                                settings,
                                getProperty(formValues.funnelSettings, [...settingsArray])
                            );
                            // immediate trigger is always true for thank you page when upsell style is popup
                            if (
                                page === 'thankyouPage' &&
                                settings === 'general.upsellStyle' &&
                                getProperty(formValues.funnelSettings, [...settingsArray]) === 'popup'
                            ) {
                                setProperty(
                                    formValues[page].splitTest[splitTestIndex].upsells[upsellIndex].downsell[
                                        downsellIndex
                                    ].upsellSettingsDifference,
                                    'general.immediateTrigger',
                                    true
                                );
                                setProperty(
                                    formValues[page].splitTest[splitTestIndex].upsells[upsellIndex].downsell[
                                        downsellIndex
                                    ].upsellSettings,
                                    'general.immediateTrigger',
                                    true
                                );
                            }
                        }
                    });
                });
            });
        });
    });
};

// settings tabs
export const SETTINGS_TABS = ['general', 'position', 'translations', 'trigger', 'position', 'inpagePositionType'];
/**
 * to covert changed settings to array of label(translation path) and value(property path)
 * @param {*object} settings changed settings from funnel settings apply to all action
 * @param {*String} from where it is from design tab or settings tab
 * if it is from design tab only include design settings other wise all other settings
 */
export const settingsToArray = (settings, from) => {
    const contents = [];
    const settingsTabs = from === 'settingsTab' ? SETTINGS_TABS : ['design'];
    settingsTabs.forEach((tab) => {
        if (settings[tab] && typeof settings[tab] === 'object' && !Array.isArray(settings[tab])) {
            Object.keys(settings[tab]).forEach((each) => {
                if (
                    settings[tab][each] &&
                    typeof settings[tab][each] === 'object' &&
                    !Array.isArray(settings[tab][each])
                ) {
                    Object.keys(settings[tab][each]).forEach((nestedEach) => {
                        if (!contents.find((settings) => settings.label === `settings.${tab}.${each}.${nestedEach}`)) {
                            contents.push({
                                label: `settings.${tab}.${each}.${nestedEach}`,
                                value: `${tab}.${each}.${nestedEach}`,
                            });
                        }
                    });
                } else if (!contents.find((settings) => settings.label === `settings.${tab}.${each}`)) {
                    contents.push({
                        label: `settings.${tab}.${each}`,
                        value: `${tab}.${each}`,
                    });
                }
            });
        } else if (settings[tab]) {
            if (!contents.find((settings) => settings.label === `settings.${tab}`)) {
                contents.push({
                    label: `settings.${tab}`,
                    value: tab,
                });
            }
        }
    });
    return contents;
};

/**
 * to check a specific propert exist in any upsell or not
 * @param {*} formValues
 * @param {*} from to know where it is from design tab or settings tab
 */
export const upsellSpecificSettings = (formValues, from) => {
    let settings = {};
    pages.forEach((page) => {
        // split test from corresponding page
        const { splitTest } = formValues[page] || {};
        (splitTest || []).forEach(({ upsells }) => {
            (upsells || []).forEach((upsell) => {
                settings = merge(settings, upsell.upsellSettingsDifference || {});
                (upsell.downsell || []).forEach((downsell) => {
                    settings = merge(settings, downsell.upsellSettingsDifference || {});
                });
            });
        });
    });
    return settingsToArray(settings, from);
};
/**
 * to find if any upsell downsell or its offers not have a single product
 * @param {*} allSplitTests all split test in a page
 */
export const customWidgetValidator = (allSplitTests) =>
    // looping thr` each upsell
    (allSplitTests || []).find((splitTest) =>
        (splitTest.upsells || []).find(
            (upsell) =>
                (upsell.offers || []).find(
                    (offer) =>
                        (upsell.upsellType !== 'volume_discount' &&
                            !offer.isTriggersOffer &&
                            (!offer.products || offer.products.length === 0)) ||
                        (upsell.upsellType === 'bundle' &&
                            !getProperty(upsell, [
                                'upsellSettingsDifference',
                                'widgetSpecific',
                                'bundle',
                                'bundleWithTriggerProduct',
                            ]) &&
                            !getProperty(upsell, [
                                'upsellSettingsDifference',
                                'widgetSpecific',
                                'bundle',
                                'hasIndividualBundleDiscount',
                            ]) &&
                            (!offer.products || offer.products.length <= 1))
                ) ||
                (upsell.downsell || []).find((downsell) =>
                    (downsell.offers || []).find(
                        (downsellOffer) =>
                            (downsell.upsellType !== 'volume_discount' &&
                                !downsellOffer.isTriggersOffer &&
                                (!downsellOffer.products || downsellOffer.products.length === 0)) ||
                            (downsell.upsellType === 'bundle' &&
                                !getProperty(downsell, [
                                    'upsellSettingsDifference',
                                    'widgetSpecific',
                                    'bundle',
                                    'bundleWithTriggerProduct',
                                ]) &&
                                (!downsellOffer.products || downsellOffer.products.length <= 1))
                    )
                )
        )
    );
/**
 * validating trigger
 * @param {String} triggerSource
 * @param {Object} trigger
 */
export const triggerSourceCheck = (triggerSource, trigger = {}) =>
    triggerSource === 'allProducts' ||
    (trigger &&
        ((trigger.products || []).length !== 0 ||
            (trigger.collections || []).length !== 0 ||
            (trigger.tags || []).length !== 0)) ||
    (triggerSource === 'cartValue' && (trigger || {}).specificCartValue > 0);

// validation in wizard fields
const getValidatorPathsByWidget = (getFieldValue, type) => {
    const newPathArray = [];
    const offers = getFieldValue(['wizard', type]);
    (offers || []).forEach((offer, index) => {
        newPathArray.push(['wizard', type, `${index}`, 'products']);
        newPathArray.push(['wizard', type, `${index}`, 'minQty']);
        newPathArray.push(['wizard', type, `${index}`, 'discount']);
    });
    return [...newPathArray];
};
export const getValidatorPathsInWizard = (getFieldValue) => {
    let pathArray = [];
    // validation trigger path
    pathArray.push(['wizard', 'trigger']);
    // validation offers paths
    pathArray = [...pathArray, ...getValidatorPathsByWidget(getFieldValue, 'offers')];
    // validation downsell paths
    pathArray = [...pathArray, ...getValidatorPathsByWidget(getFieldValue, 'downsell')];
    // validation splitTest paths
    pathArray = [...pathArray, ...getValidatorPathsByWidget(getFieldValue, 'splitTest')];

    return pathArray;
};

/**
 * for changing goToCartBtn text to 'Add & Go to cart'
 * @param {*Object} upsell upsell data
 * @param {*String} propertyName indicating current editted property
 * @param {*} value indicating current editted property value
 */
export const getGoToCartBtnText = (upsell = {}, propertyName = undefined, value = undefined) => {
    const {
        upsellType = '',
        offers = [],
        upsellSettings: {
            general: { skipCart, hideAtcForSingleOffer, upgradeProduct } = {},
            translations: { goToCartBtn } = {},
        } = {},
    } = upsell;

    const { translations: { goToCartBtn: DEFAULT_GO_TO_CART_TEXT } = {} } = defaultSettings || {};

    if (DEFAULT_GO_TO_CART_TEXT !== goToCartBtn) {
        return goToCartBtn;
    }

    switch (propertyName) {
        case 'skipCart':
            if (
                (upsellType === NORMAL_OFFER || upsellType === BUNDLE_OFFER) &&
                offers.length === 1 &&
                hideAtcForSingleOffer &&
                !value
            ) {
                return upgradeProduct ? 'Upgrade & Go to cart' : 'Add & Go to cart';
            }
            return 'Go to cart';
        case 'hideAtcForSingleOffer':
            if (
                (upsellType === NORMAL_OFFER || upsellType === BUNDLE_OFFER) &&
                offers.length === 1 &&
                !skipCart &&
                value
            ) {
                return upgradeProduct ? 'Upgrade & Go to cart' : 'Add & Go to cart';
            }
            return 'Go to cart';
        case 'offers':
            if (
                (upsellType === NORMAL_OFFER || upsellType === BUNDLE_OFFER) &&
                (value || []).length === 1 &&
                !skipCart &&
                hideAtcForSingleOffer
            ) {
                return upgradeProduct ? 'Upgrade & Go to cart' : 'Add & Go to cart';
            }
            return 'Go to cart';
        case 'upsellType':
            if (
                (value === NORMAL_OFFER || value === BUNDLE_OFFER) &&
                offers.length === 1 &&
                !skipCart &&
                hideAtcForSingleOffer
            ) {
                return upgradeProduct ? 'Upgrade & Go to cart' : 'Add & Go to cart';
            }
            return 'Go to cart';
        case 'upgradeProduct':
            if (
                (upsellType === NORMAL_OFFER || upsellType === BUNDLE_OFFER) &&
                offers.length === 1 &&
                !skipCart &&
                hideAtcForSingleOffer
            ) {
                return value ? 'Upgrade & Go to cart' : 'Add & Go to cart';
            }
            return 'Go to cart';
        default:
            if (
                (upsellType === NORMAL_OFFER || upsellType === BUNDLE_OFFER) &&
                offers.length === 1 &&
                !skipCart &&
                hideAtcForSingleOffer
            ) {
                return upgradeProduct ? 'Upgrade & Go to cart' : 'Add & Go to cart';
            }
            return 'Go to cart';
    }
};
