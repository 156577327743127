/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import http from '../../common/http/httpProvider';
import { getIt, storageEngine, isEmpty } from '../../common/helper/commonMethods';
import { getProperty } from '../../components/create-funnel/common/helper';
import { updateSourceProductConfig } from './funnel';
// import { updateSourceProductConfig } from './editor';

export const RESOURCE_PICKER_PRODUCTS_SAVE = 'RESOURCE_PICKER_PRODUCTS_SAVE';
export const API_STATUS = 'API_STATUS';

/**
 *
 * @param {*} name  wheather it is collection or tag.
 * @param {*} value will be name of collection or tag
 * @param {*} fn call back function, called once the result comes back from server.
 */
export const getProductsByCollectionOrTag = (name, value, fn) => {
    const getQuery = () => {
        if (name === 'collection') {
            return `{
                collection(id: "gid://shopify/Collection/${value}") {
                    products(first:1){
                        edges {
                            node {
                                title
                                id
                                handle
                                images(first: 1, maxWidth: 100) {
                                    edges {
                                        node {
                                            originalSrc
                                            altText
                                        }
                                    }
                                }
                                variants(first: 30) {
                                    edges {
                                        node {
                                            id
                                            title
                                            price
                                            compareAtPrice
                                            image(maxWidth: 100) {
                                                originalSrc
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }`;
        }
        return `{
                products(first: 1, , query: "${`tag:${value}`}") {
                    edges {
                        node {
                            title
                            id
                            handle
                            images(first: 1, maxWidth: 100) {
                                edges {
                                    node {
                                        originalSrc
                                        altText
                                    }
                                }
                            }
                            variants(first: 30) {
                                edges {
                                    node {
                                        id
                                        title
                                        price
                                        compareAtPrice
                                        image(maxWidth: 100) {
                                            originalSrc
                                        }
                                    }
                                }
                            }
                        }
                        cursor
                    }
                }
            }`;
    };
    http.postAction('api/v1/graphql', { query: getQuery() }).then((result) => {
        if (fn) {
            if (name === 'collection') {
                const res = result && result.data && result.data.collection ? result.data.collection : null;
                if (res) {
                    fn(result.data.collection);
                }
            } else if (result && result.date) {
                fn(result.data);
            }
            // fn(result);
        }
    });
};

export const processPoductResult = (result) => {
    const data = {};
    data.hasNextPage = (result.products.pageInfo || {}).hasNextPage;
    data.products = result.products.edges.map((a) => {
        data.cursor = a.cursor;
        a = a.node;
        const product = {};
        product.id = a.id.split('/').pop();
        product.title = a.title;
        product.image = getIt(a, `images.edges.0.node.originalSrc`, '');
        product.price = a.variants.edges[0].node.price;
        product.compareAtPrice = a.variants.edges[0].node.compareAtPrice;
        product.variants = (a.variants.edges || []).map((variant) => ({
            // id: +atob(variant.node.id).split('/').pop(),
            id: variant.node.id.split('/').pop(),
            title: variant.node.title,
            image: variant.node.image ? variant.node.image.originalSrc : null,
            price: variant.node.price,
            compareAtPrice: variant.node.compareAtPrice,
        }));
        product.selected = false;
        return product;
    });
    return data;
};

export function getProductsQueery(cursor = null, keyword = null, productLimit = 15, variantsLimit = 30) {
    return `{
        products(first: ${productLimit}, after: ${cursor ? `"${cursor}"` : null}, query: ${
        keyword ? `"${keyword}"` : null
    }) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    title
                    id
                    handle
                    hasOutOfStockVariants
                    images(first: 1, maxWidth: 400) {
                        edges {
                            node {
                                originalSrc
                                altText
                            }
                        }
                    }
                    variants(first: ${variantsLimit}) {
                        pageInfo {
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                title
                                price
                                compareAtPrice
                                availableForSale
                                image(maxWidth: 400) {
                                    originalSrc
                                  }
                            }
                            cursor
                        }
                    }
                }
            cursor
            }
        }
    }`;
}

export const getSearchQuery = (keyword = null, cursor = null) => `{
    products(first: 15, after: ${cursor ? `"${cursor}"` : null}, query: ${keyword ? `"${keyword}"` : null}) {
        pageInfo {
            hasNextPage
        }
        edges {
            node {
                title
                id
                handle
                hasOutOfStockVariants
                totalVariants
                images(first: 1, maxWidth: 400) {
                    edges {
                        node {
                            originalSrc
                            altText
                        }
                    }
                }
                variants(first: 1) {
                    pageInfo {
                        hasNextPage
                    }
                    edges {
                        node {
                            id
                            title
                            price
                            compareAtPrice
                            availableForSale
                            image(maxWidth: 400) {
                                originalSrc
                              }
                        }
                        cursor
                    }
                }
            }
        cursor
        }
    }
}`;

export const onProductSearch = (keyword, fn) => {
    const query = getSearchQuery(keyword);
    http.postAction('api/v1/graphql', { query })
        .then((result) => {
            fn(result);
        })
        .catch((err) => {
            fn(null);
        });
};
// for search only
export const fetchVariantsFromRest = (products, fn) => {
    http.postAction('api/v1/graphql/products', { products })
        .then((res) => {
            const { data } = res;
            if (Array.isArray(data) && data.length > 0) {
                fn(data);
            } else {
                // fn();
            }
        })
        .catch((err) => {
            // fn();
        });
};

export const getSingleProductByIdQuery = (id) =>
    `{
    products(first: 1, query: "${id}") {
        pageInfo {
            hasNextPage
        }
        edges {
            node {
                title
                id
                handle
                hasOutOfStockVariants
                images(first: 1, maxWidth: 400) {
                    edges {
                        node {
                            originalSrc
                            altText
                        }
                    }
                }
                variants(first: 30) {
                    pageInfo {
                        hasNextPage
                    }
                    edges {
                        node {
                            id
                            title
                            price
                            compareAtPrice
                            availableForSale
                            image(maxWidth: 400) {
                                originalSrc
                              }
                        }
                        cursor
                    }
                }
            }
        cursor
        }
    }
}`;

export const getCollectionQuery = (hasNextPage = false, cursor = null, keyword = null) => `
        {
            collections(first: 10, after: ${hasNextPage && cursor ? `"${cursor}"` : null}, query: ${
    keyword ? `"${keyword}"` : null
}) {
                pageInfo {
                    hasNextPage
                    }
            edges {
                node {
                    id
                    title
                    handle
                }
                cursor
            }
            }
        }
    `;

export const getProducts = (cursor = null, keyword = null, fn = null) => {
    const query = getProductsQueery(cursor, keyword);

    http.postAction('graphql', { query })
        .then((result) => {
            if (result) {
                const res = processPoductResult(result.data);
                if (fn) {
                    fn(res);
                }
            } else if (fn) {
                fn(null, {});
            }
        })
        .catch((e) => {
            if (fn) {
                fn(null, e);
            }
        });
};

/**
 *
 * @param {*} hasNextPage  boolean value, represent list has more number of records
 * @param {*} cursor string value, represent the start position of list to be fetched.
 * @param {*} keyword string value, represent search query.
 * @param {*} fn call back function, executed once the result comes back from server.
 */
export const getCollections = (hasNextPage, cursor, keyword, fn) => {
    const query = getCollectionQuery(hasNextPage, cursor, keyword);
    http.postAction('graphql', { query }).then((result) => {
        if (result) {
            const data = {};
            data.hasNextPage = result.data.collections.pageInfo.hasNextPage;
            data.collections = result.data.collections.edges.map((a) => {
                data.cursor = a.cursor;
                a = a.node;
                const collection = {};
                collection.id = a.id.split('/').pop();
                collection.title = a.title;
                collection.selected = false;
                collection.handle = a.handle;
                return collection;
            });
            // return data;
            if (fn) {
                fn(data);
            }
        } else if (fn) {
            fn(null);
        }
    });
};
/**
 * check wheather the details of configuration is valid, check whether the values are there  or not
 * @param {*} trigger trigger details collections | products | tag
 * @param {*} previousConfig previous configuration
 */
export const checkSourceProductValidity = (trigger, { type, value, product }) => {
    let valid = false;
    // check theather the collection or product has value
    if (trigger[type]) {
        switch (type) {
            case 'collections':
                // find the collection value
                valid = trigger[type].findIndex((a) => a.Id === value) !== -1;
                break;
            case 'tag':
                // find the collection value
                valid = trigger[type].findIndex((a) => a.title === value) !== -1;
                break;
            // products
            default:
                valid = trigger[type].findIndex((a) => a.Id === product.Id) !== -1;
                break;
        }
    }
    return valid;
};

/**
 * if the trigger selection is either collection or tag, need to find product which belongs to tag or collection.
 * @param {*} trigger trigger details, contains products | collections | tag
 */
export const onTriggerProductChange = (trigger) => (dispatch, getState) => {
    const state = getState();
    let sourceProductConfig = state.funnel.sourceProductConfig || {};
    if (!checkSourceProductValidity(trigger, sourceProductConfig)) {
        if (trigger.products && trigger.products.length > 0) {
            sourceProductConfig = {
                type: 'products',
                value: 'products',
                product: trigger.products[0],
            };
        } else if (trigger.collections && trigger.collections.length > 0) {
            sourceProductConfig = {
                type: 'collections',
                value: trigger.collections[0].id,
                product: {},
            };
            getProductsByCollectionOrTag('collection', sourceProductConfig.value, (res) => {
                const { products = [] } = processPoductResult(res);
                sourceProductConfig.product = products.length > 0 ? products[0] : undefined;
            });
        } else if (trigger.tags && trigger.tags.length > 0) {
            sourceProductConfig = {
                type: 'tags',
                value: trigger.tags[0].title,
                product: {},
            };
            getProductsByCollectionOrTag('tag', sourceProductConfig.value, (res) => {
                const { products = [] } = processPoductResult(res);
                sourceProductConfig.product = products.length > 0 ? products[0] : undefined;
            });
        }
        dispatch(updateSourceProductConfig(sourceProductConfig));
    }
};

export const getProductVariants = (cursor, productId) =>
    `{
        product(id: "${productId}") {
          variants(first: 75,after: "${cursor}") {
            edges {
              node {
                id
                title
                price
                compareAtPrice
                availableForSale
                image(maxWidth: 400) {
                originalSrc
                }
              }
              cursor
            }
          }
        }
      }`;

const mapVariantImages = (variants, productVariantImages) =>
    (variants || []).map((variant) => {
        // If image is not available for this variant then find the image and assign
        if (!variant.image) {
            const variantImage = productVariantImages.find((image) => image.variant_ids.includes(+variant.node.id));
            if (variantImage) variant.node.image.originalSrc = variantImage.src;
        }
        return variant;
    });
export const setApiStatus = (status) => ({
    type: API_STATUS,
    payload: status,
});

export const getVariantsFromRestApi = (data, fn, dispatch = null) => {
    const productArray = ((data.products || {}).edges || []).map((edge) => edge.node.id.split('/').pop());
    // console.log(productArray);
    http.postAction('api/v1/graphql/products', { products: [...productArray] })
        .then((result) => {
            if (result && result.status === 200) {
                ((data.products || {}).edges || []).forEach((element, i) => {
                    const productFromRestApi = result.data.find(
                        (pro) => pro.id === parseInt(element.node.id.split('/').pop())
                    );
                    let variantsArray = productFromRestApi.variants.map((variant) => ({
                        node: {
                            id: variant.id.toString(),
                            title: variant.title,
                            price: variant.price,
                            compareAtPrice: variant.compare_at_price,
                            availableForSale: !(variant.inventory_management && variant.inventory_quantity <= 0),
                            // image: (variant.image || {}).originalSrc || null,
                            image: variant.image ? variant.image.originalSrc : {},
                        },
                    }));
                    variantsArray = mapVariantImages(variantsArray, productFromRestApi.images || []);
                    data.products.edges[i].node.variants.edges = [...variantsArray];
                });
                // console.log(data);
                fn(data);
            } else if (dispatch) {
                dispatch(setApiStatus(false));
            }
        })
        .catch((err) => {
            if (dispatch) {
                dispatch(setApiStatus(false));
            }
        });
};

export const getShopifyProductList = (fn, dispatch = null) => {
    const query = getProductsQueery();
    http.postAction('api/v1/graphql', { query })
        .then((result) => {
            if (result.status === 200 && result.data) {
                // console.log(result.data);
                if (result.data.products.edges.find((product) => product.node.variants.pageInfo.hasNextPage)) {
                    getVariantsFromRestApi(result.data, fn, dispatch);
                } else {
                    fn(result.data);
                }
                //
                // fn(result.data);
            } else if (dispatch) {
                dispatch(setApiStatus(false));
            }
        })
        .catch((e) => {
            if (dispatch) {
                dispatch(setApiStatus(false));
            }
        });
};

export const getTagFirstProduct = (title) =>
    `{
  
                        products(query:"tag:*(${title})*", first: 1,sortKey:TITLE) {
                          pageInfo {
                                    hasNextPage
                                }
                                edges {
                                    node {
                                        title
                                        id
                                        handle
                                        hasOutOfStockVariants
                                        images(first: 1, maxWidth: 400) {
                                            edges {
                                                node {
                                                    originalSrc
                                                    altText
                                                }
                                            }
                                        }
                                        variants(first: 30) {
                                            pageInfo {
                                                hasNextPage
                                            }
                                            edges {
                                                node {
                                                    id
                                                    title
                                                    price
                                                    compareAtPrice
                                                    availableForSale
                                                    image(maxWidth: 400) {
                                                        originalSrc
                                                      }
                                                }
                                                cursor
                                            }
                                        }
                                    }
                        }
                      }
                    }`;

export const getCollectionFirstProduct = (id) =>
    `{
            collection(id: "gid://shopify/Collection/${id}") {
              products(first: 1) {
                pageInfo {
                          hasNextPage
                      }
                      edges {
                          node {
                              title
                              id
                              handle
                              hasOutOfStockVariants
                              images(first: 1, maxWidth: 400) {
                                  edges {
                                      node {
                                          originalSrc
                                          altText
                                      }
                                  }
                              }
                              variants(first: 30) {
                                  pageInfo {
                                      hasNextPage
                                  }
                                  edges {
                                      node {
                                          id
                                          title
                                          price
                                          compareAtPrice
                                          availableForSale
                                          image(maxWidth: 400) {
                                              originalSrc
                                            }
                                      }
                                      cursor
                                  }
                              }
                          }
              }
            }
          }
          }`;
const getSingleProduct = () =>
    `{
            products(first:1) {
                pageInfo {
                    hasNextPage
                }
                edges {
                    node {
                        title
                        id
                        handle
                        hasOutOfStockVariants
                        images(first: 1, maxWidth: 400) {
                            edges {
                                node {
                                    originalSrc
                                    altText
                                }
                            }
                        }
                        variants(first: 30) {
                            pageInfo {
                                hasNextPage
                            }
                            edges {
                                node {
                                    id
                                    title
                                    price
                                    compareAtPrice
                                    availableForSale
                                    image(maxWidth: 400) {
                                        originalSrc
                                      }
                                }
                                cursor
                            }
                        }
                    }
                cursor
                }
            }
        }`;

export const formatSingleProduct = (data) => {
    const formatedProduct = {};
    if (!isEmpty(data)) {
        const edges = (data.products || {}).edges || [];
        const product = Array.isArray(edges) && edges.length !== 0 ? edges[0].node : undefined;
        if (product) {
            product.variants = product
                ? ((data.products.edges[0].node.variants || {}).edges || []).map((variant) => variant.node)
                : null;

            formatedProduct.id = product.id.split('/').pop();
            formatedProduct.title = product.title;
            // formatedProduct.image = (product.images || {}).edges[0].node.originalSrc;
            formatedProduct.image = getProperty(product, ['images', 'edges', '0', 'node', 'originalSrc']);
            // formatedProduct.price = product.variants[0].price;
            formatedProduct.price = getProperty(product, ['variants', '0', 'price']);
            // formatedProduct.compareAtPrice = product.variants[0].compareAtPrice;
            formatedProduct.compareAtPrice = getProperty(product, ['variants', '0', 'compareAtPrice']);
            formatedProduct.hasOutOfStockVariants = product.hasOutOfStockVariants;
            formatedProduct.variants = (product.variants || []).map((variant) => ({
                // id: +atob(variant.node.id).split('/').pop(),
                id: variant.id.split('/').pop(),
                title: variant.title,
                image: (variant.image || {}).originalSrc || null,
                price: variant.price,
                compareAtPrice: variant.compareAtPrice,
                availableForSale: variant.availableForSale,
            }));
        }
    }
    // console.log(formatedProduct);
    return formatedProduct;
};
/**
 *
 * @param {*title of tag} title
 * @param {*} fn
 */
export const fetchFirstProductFromTag = (title, fn) => {
    let tagsProduct = storageEngine.get('tagsProduct') || [];
    if (!tagsProduct.find((tag) => tag.title === title)) {
        const query = getTagFirstProduct(title);
        http.postAction('api/v1/graphql', { query })
            .then((result) => {
                if (result.status === 200 && result.data) {
                    // store it in local storage
                    if (tagsProduct) {
                        tagsProduct.push({
                            title,
                            product: result.data,
                        });
                    } else {
                        tagsProduct = [
                            {
                                title,
                                product: result.data,
                            },
                        ];
                    }
                    storageEngine.set('tagsProduct', [...tagsProduct]);
                    const data = formatSingleProduct(result.data);
                    fn({ ...data });
                }
            })
            .catch((e) => null);
    } else {
        const result = tagsProduct.find((tag) => tag.title === title);
        const data = formatSingleProduct(result.product);
        fn({ ...data });
    }
};
/**
 *
 * @param {*id of collection} id
 * @param {*} fn
 */
export const fetchFirstProductFromCollection = (id, fn) => {
    let collectionsProduct = storageEngine.get('collectionsProduct') || [];
    if (!collectionsProduct.find((collection) => collection.id === id)) {
        const query = getCollectionFirstProduct(id);
        http.postAction('api/v1/graphql', { query })
            .then((result) => {
                if (result.status === 200 && result.data) {
                    // to store single product in local storage
                    if (collectionsProduct) {
                        collectionsProduct.push({
                            id,
                            product: result.data,
                        });
                    } else {
                        collectionsProduct = [
                            {
                                id,
                                product: result.data,
                            },
                        ];
                    }
                    storageEngine.set('collectionsProduct', [...collectionsProduct]);
                    const data = formatSingleProduct(result.data.collection);
                    fn({ ...data });
                }
            })
            .catch((e) => null);
    } else {
        const result = collectionsProduct.find((collection) => collection.id === id);
        const data = formatSingleProduct(result.product.collection);
        fn({ ...data });
    }
};

export const fetchSingleProduct = (fn) => {
    const singleProduct = storageEngine.get('singleProduct') || {};
    if (isEmpty(singleProduct)) {
        const query = getSingleProduct();
        http.postAction('api/v1/graphql', { query })
            .then((result) => {
                if (result.status === 200 && result.data) {
                    // to store single product in local storage
                    storageEngine.set('singleProduct', { ...result.data });
                    const data = formatSingleProduct(result.data);
                    fn({ ...data });
                }
            })
            .catch((e) => null);
    } else {
        const data = formatSingleProduct(singleProduct);
        fn({ ...data });
    }
};

export const getVariants = (sourceProduct, shopifyProductList, fn) => {
    if (
        !shopifyProductList ||
        isEmpty(shopifyProductList.products) ||
        !Array.isArray(shopifyProductList.products.edges) ||
        !shopifyProductList.products.edges.find((product) => product.node.id.split('/').pop() === sourceProduct.id)
    ) {
        // if it is in local
        const singleProduct = storageEngine.get('singleProduct') || {};
        const edges = (singleProduct.products || {}).edges || [];
        const product = Array.isArray(edges) && edges.length !== 0 ? edges[0].node : undefined;
        if (product && product.id.split('/').pop() === sourceProduct.id) {
            const newProduct = formatSingleProduct(product);
            fn({ ...newProduct });
        } else {
            const query = getSingleProductByIdQuery(sourceProduct.id);
            http.postAction('api/v1/graphql', { query })
                .then((result) => {
                    if (result.status === 200 && result.data) {
                        // to store single product in local storage
                        storageEngine.set('singleProduct', { ...result.data });
                        const data = formatSingleProduct(result.data);
                        fn({ ...data });
                    }
                })
                .catch((e) => null);
        }
    } else {
        const newProduct = shopifyProductList.products.edges.find(
            (product) => product.node.id.split('/').pop() === sourceProduct.id
        );

        const variants = newProduct.node.variants.edges.map((variant) => variant.node);
        fn({ ...sourceProduct, variants });
    }
};

/**
 * getting all image of variants
 */
export const getVariantsImages = (productId) => {
    /** graphql query */
    const query = `{
        product(id: "gid://shopify/Product/${productId}") {
          variants(first: 15) {
            edges {
              node {
                image(maxWidth: 400) {
                    originalSrc
                }
              }
            }
          }
        }
      }`;
    /** posting to backend */
    return http.postAction('api/v1/graphql', { query });
};

// actions
export const saveShopifyProductList = (productList) => ({
    type: RESOURCE_PICKER_PRODUCTS_SAVE,
    payload: productList,
});
