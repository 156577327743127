import { UFE_UPSELL_FUNNEL_LISTING, DELETE_FUNNELS_FROM_LIST } from '../actions';

const initialState = {};
/**
 * Handle funnels data from redux state
 * @param {*} state  dashboard state from redux store
 * @param {*} action dispatched action details contains `type` and `payload`
 */
export const funnelList = (state = initialState, action) => {
    switch (action.type) {
        // save upsell funnels data to redux state
        case UFE_UPSELL_FUNNEL_LISTING:
            return {
                ...state,
                upsellFunnelEngine: action.payload,
            };
        // remove specified upsell funnel data using id.
        case DELETE_FUNNELS_FROM_LIST: {
            const upsellFunnelEngine = state.upsellFunnelEngine || {};
            return {
                ...state,
                upsellFunnelEngine: {
                    ...state.upsellFunnelEngine,
                    data: (upsellFunnelEngine.data || []).filter((funnel) => funnel.id !== action.payload.id),
                    status: (upsellFunnelEngine.data || []).length === 1 ? 'NO_DATA' : 'SUCCESS',
                },
            };
        }
        default:
            return { ...state };
    }
};
