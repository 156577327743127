import http from '../../common/http/httpProvider';
// action name for saving analytics data
export const UFE_DASHBOARD_SAVE_ANALYTICS = 'UFE_DASHBOARD_SAVE_ANALYTICS';
export const DEFAULT_TIME_PERIOD = 30;

/**
 * `Returns` action of type `UFE_DASHBOARD_SAVE_ANALYTICS`
 * @param {*} analytics data to be saved to redux
 */
export const saveDashboardAnalytics = (analytics) => ({
    type: UFE_DASHBOARD_SAVE_ANALYTICS,
    payload: analytics,
});

/**
 * Fetch dashboard analytics from server
 */
export const getDashboardData = (startDate, endDate, next = () => {}) => (dispatch, getState) => {
    http.postAction('api/v1/dashboard/analytics', {
        startDate,
        endDate,
    }).then((res) => {
        if (res) {
            dispatch(saveDashboardAnalytics(res.data));
            next();
        }
    });
};
