import dayjs from 'dayjs';

export const validateNovemberPlan = (lastInstalled) => {
    const lastInstalledDate = new Date(lastInstalled);
    const offerStartingDate = new Date(2020, 10, 5);

    return lastInstalledDate.getTime() > offerStartingDate.getTime();
};

// export const checkIsInTrial = (firstInstalled, lastInstalled, trialLimit) => {
//     const today = dayjs();
//     const installedDate = lastInstalled ? dayjs(lastInstalled) : dayjs(firstInstalled);
//     const dateDifference = today.diff(installedDate, 'day');
//     return dateDifference <= trialLimit;
// };
