import dayjs from 'dayjs';
import http from '../../common/http/httpProvider';
import { storageEngine } from '../../common/helper/commonMethods';
import { FREE_PLAN_SELECTED, TRIAL_DAYS } from '../../common/constants';

export const ORDERS_COUNT = 'ordersCount';
const DISABLE_REDIRECT = false;
export const MONTHLY_ORDERS_LIMIT = 50;
const REDIRECT_TO_PRICING_IMPLEMENTED_DATE = {
    year: '2020',
    month: '09',
    day: '19',
    hours: '00',
    minutes: '01',
};

export const getOrdersCount = (advanced) => {
    const url = advanced ? 'api/v1/order/count/from/last-billing' : 'api/v1/order/count';
    return http
        .getAction(url)
        .then((response = {}) => {
            const { count = 0 } = response.data;
            return count;
        })
        .catch((err) => 0);
};

const validatePlan = (plan) => plan !== 'staff' && plan !== 'staff_business';

export const isRedirectToUpgrade = () => {
    const user = storageEngine.get('user') || {};
    const skipUpgradeCheck = storageEngine.get('skip_upgrade_check');
    const {
        ufe_plan: ufePlan,
        last_installed: lastInstalled,
        plan_name: planName,
        first_installed: firstInstalled,
        freePlanSelected,
    } = user || {};

    const { year, month, day, hours, minutes } = REDIRECT_TO_PRICING_IMPLEMENTED_DATE;
    const releasedDate = new Date(year, month, day, hours, minutes);
    const lastInstalledDate = new Date(lastInstalled);

    const FreePlanSelectedLocalData = storageEngine.get(FREE_PLAN_SELECTED);
    const FreePlanSelectedData = freePlanSelected || FreePlanSelectedLocalData === true;

    const installedDate = lastInstalled ? dayjs(lastInstalled) : dayjs(firstInstalled);
    const dateDifference = dayjs().diff(installedDate, 'day');
    // for new user not consider trial
    const isNewUser = storageEngine.get('isNewUser', true, 'session');
    const isInTrial = dateDifference <= TRIAL_DAYS && !(isNewUser === true || isNewUser === 'true');

    if (
        !DISABLE_REDIRECT &&
        ufePlan === 'free' &&
        // ordersCount > 50 &&
        lastInstalled &&
        releasedDate < lastInstalledDate &&
        validatePlan(planName) &&
        !skipUpgradeCheck &&
        !isNewUser
    ) {
        // check continue as free also
        return !FreePlanSelectedData;
    }
    return false;
};

// check max hit reached
export const checkMaxHitReached = (ordersCount) => ordersCount > MONTHLY_ORDERS_LIMIT;

// for redux

export const SET_LAST_THIRTY_DAYS_COUNT = 'SET_MONTHLY_ORDERS_COUNT';
export const SET_CURRENT_MONTH_COUNT = 'SET_CURRENT_MONTH_COUNT';

export const saveLastThirtyDaysOrdersCount = (count) => ({
    type: SET_LAST_THIRTY_DAYS_COUNT,
    payload: count,
});

export const saveCurrentMonthOrdersCount = (count) => ({
    type: SET_CURRENT_MONTH_COUNT,
    payload: count,
});
