import { UFE_SAVE_CHANGED_COMMON_SETTINGS } from '../actions/changedSettings';

export const changedSettings = (state = {}, action) => {
    switch (action.type) {
        case UFE_SAVE_CHANGED_COMMON_SETTINGS:
            return {
                ...action.payload,
            };

        default:
            return {
                ...state,
            };
    }
};
