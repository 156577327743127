/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import * as reducers from './reducers';

const rootReducer = combineReducers(
    reducers
    // {
    // loader, dashboard, resourcePicker, funnel
    // }
);
// configure middleware
/**
 * Why thunk?
 * https://stackoverflow.com/questions/34570758/why-do-we-need-middleware-for-async-flow-in-redux/34599594#34599594
 */
let applyMiddlewareVar;
const middleware = [thunk];
// ToDo : Add it later
// process.env.NODE_ENV !== 'production' ||
if (process.env.NODE_ENV !== 'production') {
    const { logger } = require('redux-logger');
    middleware.push(logger);
    applyMiddlewareVar = composeWithDevTools(applyMiddleware(...middleware));
} else applyMiddlewareVar = applyMiddleware(...middleware);

export const store = createStore(rootReducer, applyMiddlewareVar);
