import React from 'react';
import { Button, Typography, Row } from 'antd';
import { storageEngine } from '../../../../common/helper/commonMethods';
import { ORDERS_COUNT } from '../../../../state/actions/orders';
import { validateNovemberPlan } from '../../common/commomMethods';

const { Text, Title } = Typography;

export default function ({ showOfferPrice, fixedPrice, offerPrice, onSubmit, time, showTimer }) {
    /**
     * converting milleseconds to minutes and seconds
     */
    const minutes = Math.floor((time / (1000 * 60)) % 60);
    const seconds = Math.floor((time / 1000) % 60);

    /**
     * price details
     */
    const orderCount = storageEngine.get(ORDERS_COUNT);
    const user = storageEngine.get('user');
    const { last_installed: lastInstalled } = user;

    const isNovemberPlanAvailable = validateNovemberPlan(lastInstalled);

    // const maxPrice = isNovemberPlanAvailable
    //     ? getPrice(planName, orderCount, prioritySupport, pricingAmount, createdAt, isNovemberPlanAvailable) * 2
    //     : ufePricePlans[ufePricePlans.length - 2].monthlyPrice;

    const getContent = () => {
        if (showOfferPrice) {
            if (showTimer) {
                return (
                    <>
                        <Title level={3}>
                            Offer Ends in:{' '}
                            <span>{`${minutes < 9 ? `0${minutes}` : minutes} : ${
                                seconds < 9 ? `0${seconds}` : seconds
                            }`}</span>
                        </Title>
                        <Row>
                            <Text>{`$${offerPrice} Offer Price`}</Text>
                        </Row>
                        <Row>
                            <Text delete>{`$${fixedPrice} Fixed Price`}</Text>
                        </Row>
                    </>
                );
            }

            return (
                <>
                    <Row>
                        <Text>{`$${offerPrice} Offer Price`}</Text>
                    </Row>
                </>
            );
        }

        return (
            <>
                <Text>{fixedPrice}</Text>
            </>
        );
    };

    return (
        <div>
            <Title level={4} style={{ color: '#ff1919' }}>
                Limited Time offer only for you! Offer may change anytime!
            </Title>

            {getContent()}
            <Button>Move to this plan</Button>
        </div>
    );
}
