/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback } from 'react';
import { Menu, Badge } from 'antd';
import { withRouter } from 'react-router-dom';
import {
    PieChartOutlined,
    FilterOutlined,
    MessageOutlined,
    RadiusSettingOutlined,
    RocketOutlined,
    QuestionOutlined,
    SettingOutlined,
    ReadOutlined,
    AppstoreAddOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { storageEngine } from '../../../../common/helper/commonMethods';
import { triggerMessage } from '../../../../utils/crispChat';
import { OPEN_CHAT } from '../../../../common/constants';
import './menu-styles.scss';

export default withRouter(function (props) {
    const { isMobile, history, location } = props;
    const { t } = useTranslation();
    /**
     * `Returns` selected menu based on url
     */
    const getSelectedMenu = useCallback((pathName) => {
        const paths = pathName.split('/');
        const path = paths.filter((a) => a !== '').length ? paths.filter((a) => a !== '')[0] : null;
        switch (path) {
            case 'dashboard':
                return ['1'];
            case 'funnel':
                return ['2'];
            case 'suggest':
                return ['3'];
            case 'changelog':
                return ['4'];
            case 'settings':
                return ['6'];
            case 'faqs':
                return ['5'];
            default:
                return [];
        }
    }, []);

    return (
        <Menu
            className="ufe-menu"
            theme="white"
            mode={isMobile ? 'inline' : 'horizontal'}
            selectedKeys={getSelectedMenu(location.pathname)}
        >
            <Menu.Item key="1" onClick={() => history.push('/dashboard')}>
                <PieChartOutlined />
                <span>{t('template.header.menu.dashboard')}</span>
            </Menu.Item>
            <Menu.Item key="2" onClick={() => history.push('/funnel')}>
                <FilterOutlined />
                <span>{t('template.header.menu.funnels')}</span>
            </Menu.Item>
            <Menu.Item key="4" onClick={() => history.push('/tutorials')}>
                <Badge dot>
                    <ReadOutlined />
                    <span>{t('template.header.menu.tutorials')}</span>
                </Badge>
            </Menu.Item>
            <Menu.Item
                key="3"
                onClick={() => {
                    triggerMessage(OPEN_CHAT);
                }}
            >
                {/* <Icon type="message" /> */}
                <MessageOutlined />
                <span>{t('template.header.menu.needHelp')}</span>
            </Menu.Item>
            <Menu.Item key="7" onClick={() => window.open('https://feature.ufe.helixo.co/', '_blank')}>
                <Badge dot>
                    <AppstoreAddOutlined />
                    <span>{t('template.header.menu.feature')}</span>
                </Badge>
            </Menu.Item>
            {/* <Menu.Item
                key="5"
                onClick={() => {
                    history.push('/faqs');
                }}
            >
                {/* <Icon type="message" /> */}
            {/* <QuestionOutlined />
                <span>Faq`s</span>
            </Menu.Item> */}

            <Menu.Item key="6" onClick={() => history.push('/settings')}>
                {/* <Icon type="setting" /> */}
                <SettingOutlined />
                <span>{t('template.header.menu.settings')}</span>
            </Menu.Item>
        </Menu>
    );
});
