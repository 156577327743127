import { RESOURCE_PICKER_PRODUCTS_SAVE, API_STATUS } from '../actions/resourcePicker';

export const resourcePicker = (state = {}, action) => {
    switch (action.type) {
        case RESOURCE_PICKER_PRODUCTS_SAVE:
            return {
                ...state,
                shopifyProductList: { ...action.payload },
            };
        case API_STATUS:
            return {
                ...state,
                shopifyProductsApiStatus: action.payload,
            };
        default:
            return state;
    }
};
