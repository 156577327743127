import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

export default function () {
    return (
        <Footer style={{ textAlign: 'center' }}>
            Copyright ©{new Date().getFullYear()} Helixo. All Rights Reserved.
        </Footer>
    );
}
