/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Layout, Popover } from 'antd';
import { withRouter } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import logo from '~images/ufe2-logo.png';
import MenuUserOptions from './menu-user-options';
import Menu from './menu';
import './Header.scss';

const { Header } = Layout;

export default withRouter(function ({ history }) {
    const [isMobile, ChangeDisplayViewType] = useState(false);
    const [menuVisible, toggleMenu] = useState(false);
    /**
     * Check wheather it is mobile device or not
     */
    const isMobileDevice = () => {
        const mobileUserAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        );
        const mobileScreenSize = window.screen.width < 720;
        return mobileUserAgent && mobileScreenSize;
    };
    /**
     * Execute on every window resize
     */
    const handleResize = () => {
        const isMobileView = isMobileDevice();
        window.ufeapp.isMobileDevice = isMobileView;
        console.log(`resize`, isMobileView);
        ChangeDisplayViewType(isMobileView);
    };

    const redirectToDashboard = () => {
        history.push('/dashboard');
    };

    return (
        <Header style={{ background: '#fff' }}>
            <div className="logo" style={{ float: 'left' }}>
                <img alt="Upsell Funnel Engine Logo" src={logo} onClick={redirectToDashboard} />
                {/* <span className="ufeBadge">v2.0</span> */}
            </div>
            {isMobile ? (
                <div className="mobile-menu">
                    <MenuUserOptions />
                    <Popover
                        overlayClassName="popover-menu"
                        placement="bottomRight"
                        content={<Menu isMobile={isMobile} />}
                        trigger="click"
                        visible={menuVisible}
                        arrowPointAtCenter
                        onVisibleChange={() => toggleMenu(!menuVisible)}
                    >
                        <MenuOutlined onClick={() => toggleMenu(!menuVisible)} />
                        {/* <Icon
                        className="ufe-menu-icon"
                        type="menu"
                        onClick={() => toggleMenu(!menuVisible)}
                    /> */}
                    </Popover>
                </div>
            ) : (
                <div className="ufe-menu-wrap">
                    <Menu isMobile={isMobile} />
                    <MenuUserOptions />
                </div>
            )}
        </Header>
    );
});
