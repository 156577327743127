import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs, Alert } from 'antd';
import { DisconnectOutlined, MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { updateNotifications } from '../../../../../state/actions/notifications';
import http from '../../../../../common/http/httpProvider';

const { TabPane } = Tabs;

export default function () {
    const dispatch = useDispatch();
    const notifications = useSelector((state) => state.notifications || []);

    const notificationsList = notifications.filter((each) => each.type === 'error');
    const messagesList = notifications.filter((each) => each.type === 'info' || each.type === 'success');
    const { t } = useTranslation();

    useEffect(() => {
        // loop through list to find any one of them is not read
        if ([...notificationsList, ...messagesList].find((each) => !each.read)) {
            const ids = [...notificationsList, ...messagesList].filter((notification) => !notification.read);
            // updating read status
            http.postAction('api/v1/promotion//notification/read', { ids })
                .then(() => {
                    // updating status locally
                    const newNotifications = notifications.map((notification) => {
                        if (
                            notification.type === 'error' ||
                            notification.type === 'info' ||
                            notification.type === 'success'
                        ) {
                            return {
                                ...notification,
                                read: true,
                            };
                        }
                        return notification;
                    });
                    dispatch(updateNotifications(newNotifications));
                })
                .catch((err) => {
                    // err
                });
        }
    }, [notificationsList, messagesList, dispatch, notifications]);

    // to get notification type
    const getNotificationType = (notification) => {
        if (notification.type === 'error') {
            if (notification.resolved) {
                return 'success';
            }
            return notification.type;
        }
        return notification.type;
    };
    return (
        <Tabs defaultActiveKey="1">
            <TabPane tab={t('template.header.notification.notifications')} key="1">
                {notificationsList.length === 0 ? (
                    <div className="ufe-full-center ufe-no-content">
                        <span id="emoji" role="img" aria-label="">
                            <DisconnectOutlined />
                            {/* <Icon type="disconnect" /> */}
                        </span>{' '}
                        {t('template.header.notification.noNotifications')}
                    </div>
                ) : (
                    notificationsList.map((notification) => (
                        <Alert
                            message={notification.message}
                            type={getNotificationType(notification)}
                            showIcon
                            banner
                        />
                    ))
                )}
            </TabPane>
            <TabPane tab={t('template.header.notification.messages')} key="2">
                {messagesList.length === 0 ? (
                    <div className="ufe-full-center ufe-no-content">
                        <span id="emoji" role="img" aria-label="">
                            <MailOutlined />
                            {/* <Icon type="mail" /> */}
                        </span>
                        <span>{t('template.header.notification.noMessages')}</span>
                    </div>
                ) : (
                    messagesList.map((message) => (
                        <Alert message={message.message} type={message.type} showIcon banner />
                    ))
                )}
            </TabPane>
        </Tabs>
    );
}
