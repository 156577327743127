import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import http from '../../common/http/httpProvider';
import { globalState } from '../../common/helper/globalState';

// action name for saving funnel engine data
export const UFE_UPSELL_FUNNEL_LISTING = 'UFE_UPSELL_FUNNEL_LISTING';
export const DELETE_FUNNELS_FROM_LIST = 'DELETE_FUNNELS_FROM_LIST';
export const DUPLICATE_FUNNELS_FROM_LIST = 'DUPLICATE_FUNNELS_FROM_LIST';

/**
 * `Returns` action of type `UFE_FUNNEL_LISTING`
 * @param {*} upsellFunnelEngineData data to be saved to redux state
 */
export const saveFunnelEngineData = (upsellFunnelEngineData) => ({
    type: UFE_UPSELL_FUNNEL_LISTING,
    payload: upsellFunnelEngineData,
});

/**
 * `Returns` action of type `DELETE_FUNNELS_FROM_LIST`
 * @param {*} id upsell funnel id to be deleted.
 */
export const deleteFunnelEngineData = (id) => ({
    type: DELETE_FUNNELS_FROM_LIST,
    payload: {
        id,
    },
});

/**
 * Get funnel engine data from server
 */
export const getFunnelEngineDataFromServer = (setLoader = null, isMounted) => (dispatch, getState) => {
    http.getAction('api/v1/funnel/all').then((upsellFunnelEngineData) => {
        let sendData = { data: {} };
        if (upsellFunnelEngineData) {
            const data = (upsellFunnelEngineData.data || []).map((d, i) => ({
                key: i,
                id: d.id,
                funnelname: d.funnelName,
                active: d.active,
                trigger: d.trigger,
                triggerSource: d.triggerSource,
                pageviews: d.data.pageviews,
                conversions: d.data.conversions,
                revenue: d.data.conversionValue,
                upsellaccepts: d.data.accepts,
                upsellrejects: d.data.rejects,
                checkoutinits: d.data.checkoutInits,
            }));
            sendData = { ...sendData, data };
            sendData.status = !data.length ? 'NO_DATA' : 'SUCCESS';
            if (setLoader) setLoader(false);
        } else {
            sendData.status = 'NO_DATA';
            if (setLoader) setLoader(false);
        }

        dispatch(saveFunnelEngineData(sendData));
    });
};

/**
 * Update upsell funnel status.
 * @param {*} active upsell funnel status
 * @param {*} id upsell funnel id to update the status
 */
export const updateUpsellFunnelStatus = (active, id) => (dispatch, getstate) => {
    const { translate: t } = globalState;
    const state = getstate();
    const index = (state.funnelList.upsellFunnelEngine.data || []).findIndex((a) => a.id === id);
    if (index !== -1) {
        http.postAction('api/v1/funnel/setStatus', { id, active })
            .then((response) => {
                const { data } = state.funnelList.upsellFunnelEngine;
                data[index].active = active;
                const sendData = { data };
                sendData.status = !data.length ? 'NO_DATA' : 'SUCCESS';
                dispatch(saveFunnelEngineData(sendData));
            })
            .catch((e) => {
                notification.error({ message: t('errorMessages.errorMessage') });
            });
    }
};

/**
 * Delete upsell funnel with id.
 * @param {*} id upsell funnel id to delete.
 * @param {*} isPrompts boolean value for prompt.
 */
export const deleteFunnelFromList = (id, isPrompts = true) => (dispatch) => {
    http.deleteAction(`api/v1/funnel/${id}`)
        .then((response) => {
            if (isPrompts) {
                notification.success({
                    message: response.data.message,
                });
                dispatch(deleteFunnelEngineData(id));
            }
        })
        .catch((e) => {
            notification.success({ message: 'deleted' });
            dispatch(deleteFunnelEngineData(id));
        });
};

// // Pending Item.
export const duplicateFunnelFromList = (id, dispatch, isPrompts = true) => {
    http.postAction('api/v1/funnel/duplicate', { id })
        .then((response) => {
            if (isPrompts) {
                notification.success({
                    message: response.data.message,
                });
                dispatch(getFunnelEngineDataFromServer());
            }
        })
        .catch((e) => {
            notification.error({ message: e.message || 'Error' });
            dispatch({
                type: DUPLICATE_FUNNELS_FROM_LIST,
                payload: {
                    id,
                },
            });
        });
};
